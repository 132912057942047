<template>
  <div class="v-date-picker">
    <label v-if="label" v-text="label"></label>
    <el-date-picker
      :format="format"
      v-model="model"
      :type="type"
      :placeholder="placeholder"
    >
    </el-date-picker>
  </div>
</template>

<script>
import Vue from "vue";
import { DatePicker } from "element-ui";
Vue.use(DatePicker);
export default {
  watch: {
    value(newValue, oldValue) {
      this.changeModel(newValue);
    },
    model(newValue) {
      this.$emit("input", newValue, this);
    },
  },
  created() {},
  components: {},
  props: {
    format: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    // 按钮宽度
    width: Number,
    type: {
      type: String,
      default: "date",
    },
    value: Date,
    text: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      model: this.value,
    };
  },
  methods: {
    changeModel(newValue) {
      this.model = newValue;
      // 输入框的值改变时触发
      this.$emit("change", newValue);
    },
    change(value) {
      this.$emit("change", value);
    },
  },
};
</script>
<style lang="less">
.v-date-picker {
  margin: 5px 20px 5px 0;
}
</style>
