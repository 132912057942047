<template>
  <div class="v-time">
    <el-time-picker
      :style="{
        width: `${width}px`,
      }"
      v-model="model"
      :picker-options="pickerOptions"
      :format="format"
      :value-format="valueFormat"
      :arrow-control="arrowControl"
      :is-range="isRange"
      v-bind="{
        ...bindOps,
      }"
      :start-placeholder="startPlaceholder"
      :end-placeholder="endPlaceholde"
      :placeholder="placeholder"
      :disabled="disabled"
    >
    </el-time-picker>
  </div>
</template>

<script>
import Vue from "vue";
import { TimePicker } from "element-ui";
Vue.use(TimePicker);
export default {
  watch: {
    value(newValue, oldValue) {
      this.changeModel(newValue);
    },
    model(newValue) {
      this.$emit("input", newValue, this);
    },
  },
  props: {
    // 按钮宽度
    width: Number,
    type: {
      type: String,
      default: "date",
    },
    value: [String, Date, Array],
    // selectableRange: {
    //   type: String,
    //   default: "00:00:00 - 23:59:59",
    // },

    /**  */
    pickerOptions: {
      type: Object,
      default() {
        return {
          selectableRange: "00:00:00 - 23:59:59",
        };
      },
    },
    // 显示的格式
    format: String,
    // 值的格式
    valueFormat: String,
    // 是否时间范围选择
    isRange: {
      type: Boolean,
      default: false,
    },
    // 选择框样式——是否有上下箭头
    arrowControl: {
      type: Boolean,
      default: false,
    },
    rangeSeparator: {
      type: String,
      default: "至",
    },
    startPlaceholder: {
      type: String,
      default: "开始时间",
    },
    endPlaceholde: {
      type: String,
      default: "结束时间",
    },
    placeholder: {
      type: String,
      default: "任意时间点",
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      model: this.value,
    };
  },
  computed: {
    bindOps() {
      let emptyObj = {};
      /* 如果使用时间范围选择器, pickerOptions.selectableRange 的值的设置方式不能使用默认方式，否则控件会报错 */
      this.isRange && (emptyObj.rangeSeparator = this.rangeSeparator);
      return emptyObj;
    },
  },
  methods: {
    changeModel(newValue) {
      this.model = newValue;
      // 输入框的值改变时触发
      this.$emit("change", newValue);
    },
    change(value) {
      this.$emit("change", value);
    },
  },
};
</script>
<style lang="less"></style>
