const routes = [
  {
    path: "futureEdu/chapterList",
    name: "chapterList",
    meta: {
      title: "章节列表",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/cloudSchool/chapterList.vue"], resolve),
  },
  {
    path: "futureEdu/chapterForm",
    name: "chapterForm",
    meta: {
      title: "新增章节",
      echo: "futureEdu/chapterList",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/cloudSchool/chapterForm.vue"], resolve),
  },
  {
    path: "futureEdu/courseList",
    name: "courseList",
    meta: {
      title: "课程列表",
      echo: "futureEdu/chapterList",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/cloudSchool/courseList.vue"], resolve),
  },
  {
    path: "futureEdu/courseForm",
    name: "courseForm",
    meta: {
      title: "新增课程",
      echo: "futureEdu/chapterList",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/cloudSchool/courseForm.vue"], resolve),
  },
  /** 明珠托幼 */
  {
    path: "futureEdu/entrustChild",
    name: "entrustChild",
    meta: {
      title: "托幼课程",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/entrustChild/index.vue"], resolve),
  },
  {
    path: "futureEdu/entrustChildUser",
    name: "entrustChildUser",
    meta: {
      title: "意见反馈",
      echo: "futureEdu/entrustChild",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/entrustChild/entrustChildUser.vue"], resolve),
  },
  {
    path: "futureEdu/entrustChildUserForm",
    name: "entrustChildUserForm",
    meta: {
      title: "意见反馈表单",
      echo: "futureEdu/entrustChild",
    },
    component: (resolve) =>
      require([
        "@/views/futureEdu/entrustChild/entrustChildUserForm.vue",
      ], resolve),
  },
  {
    path: "futureEdu/entrustChildForm",
    name: "entrustChildForm",
    meta: {
      title: "课程表单",
      echo: "futureEdu/entrustChild",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/entrustChild/entrustChildForm.vue"], resolve),
  },
  // 直播管理
  {
    path: "futureEdu/liveBroadcastManage",
    name: "liveBroadcastManage",
    meta: {
      title: "直播管理",
      echo: "futureEdu/liveBroadcastManage",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/liveBroadcastManage/index.vue"], resolve),
  },
  //教育地图总览
  {
    path: "futureEdu/educationalMapOverview",
    name: "educationalMapOverview",
    meta: {
      title: "教育地图总览",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/educationalMapOverview/index.vue"], resolve),
  },
  //红领巾专线管理
  {
    path: "futureEdu/redScarfManagement",
    name: "redScarfManagement",
    meta: {
      title: "红领巾专线管理",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/redScarfManagement/index.vue"], resolve),
  },
  {
    path: "futureEdu/redScarfManagemenForm",
    name: "redScarfManagemenForm",
    meta: {
      title: "红领巾专线管理",
      echo: "futureEdu/redScarfManagement",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/redScarfManagement/redScarfManagemenForm.vue"], resolve),
  },
];

export default routes;
