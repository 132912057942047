const routes = [
  {
    path: "analysisData/runCondition",
    name: "runCondition",
    meta: {
      title: "运行情况",
    },
    component: (resolve) =>
      require(["@/views/analysisData/runCondition/index.vue"], resolve),
  },
  {
    path: "analysisData/home",
    name: "analysisData",
    meta: {
      title: "数据分析总览",
    },
    component: (resolve) =>
      require(["@/views/analysisData/home/index.vue"], resolve),
  },
  {
    path: "analysisData/sourceData",
    name: "sourceData",
    meta: {
      title: "数据源",
    },
    component: (resolve) =>
      require(["@/views/analysisData/sourceData/index.vue"], resolve),
  },
  {
    path: "analysisData/sourceDataAdd",
    name: "sourceDataAdd",
    meta: {
      echo: "analysisData/sourceData",
      title: "新增数据源",
    },
    component: (resolve) =>
      require(["@/views/analysisData/sourceData/add.vue"], resolve),
  },
  {
    path: "analysisData/assetManage",
    name: "assetManage",
    meta: {
      title: "数据资产管理",
    },
    component: (resolve) =>
      require(["@/views/analysisData/assetModel/assetManage.vue"], resolve),
  },
  {
    path: "analysisData/addManage",
    name: "addMange",
    meta: {
      title: "新增资产管理",
      echo: "analysisData/assetManage",
    },
    component: (resolve) =>
      require(["@/views/analysisData/assetModel/addManage.vue"], resolve),
  },
  {
    path: "analysisData/modelManage",
    name: "modelManage",
    meta: {
      title: "数据模型管理",
    },
    component: (resolve) =>
      require(["@/views/analysisData/assetModel/modelManage.vue"], resolve),
  },
  {
    path: "analysisData/addModel",
    name: "addModel",
    meta: {
      title: "新建模型",
      echo: "analysisData/modelManage",
    },
    component: (resolve) =>
      require(["@/views/analysisData/assetModel/addModel.vue"], resolve),
  },
  {
    path: "analysisData/storeManage",
    name: "storeManage",
    meta: {
      title: "数据存储管理",
    },
    component: (resolve) =>
      require(["@/views/analysisData/storeManage/index.vue"], resolve),
  },
  {
    path: "analysisData/dataAnaly",
    name: "dataAnaly",
    meta: {
      title: "数据分析",
    },
    component: (resolve) =>
      require(["@/views/analysisData/dataAnaly/index.vue"], resolve),
  },
  {
    path: "analysisData/dataMarket",
    name: "dataMarket",
    meta: {
      title: "数据集市",
    },
    component: (resolve) =>
      require(["@/views/analysisData/dataMarket/index.vue"], resolve),
  },
  // 数据质量报告
  {
    path: "analysisData/dataQualityAnalysis",
    name: "dataQualityAnalysis",
    meta: {
      title: "数据质量报告",
    },
    component: (resolve) =>
      require(["@/views/analysisData/dataQualityAnalysis/index.vue"], resolve),
  },

  {
    path: "analysisData/sensitiveWords",
    name: "sensitiveWords",
    meta: {
      title: "敏感词管理",
    },
    component: (resolve) =>
      require([
        "@/views/analysisData/dataSecurity/sensitiveWords.vue",
      ], resolve),
  },
  {
    path: "analysisData/addSensitiveWords",
    name: "addSensitiveWords",
    meta: {
      title: "新增敏感词",
    },
    component: (resolve) =>
      require([
        "@/views/analysisData/dataSecurity/addSensitiveWords.vue",
      ], resolve),
  },
  {
    path: "analysisData/desensitizationRule",
    name: "desensitizationRule",
    meta: {
      title: "脱敏规则",
    },
    component: (resolve) =>
      require([
        "@/views/analysisData/dataSecurity/desensitizationRule.vue",
      ], resolve),
  },
  {
    path: "analysisData/encryptionRule",
    name: "encryptionRule",
    meta: {
      title: "加密规则",
    },
    component: (resolve) =>
      require([
        "@/views/analysisData/dataSecurity/encryptionRule.vue",
      ], resolve),
  },
  {
    path: "analysisData/encryption",
    name: "encryption",
    meta: {
      title: "加密算法",
    },
    component: (resolve) =>
      require(["@/views/analysisData/dataSecurity/encryption.vue"], resolve),
  },
  // 数据检验规则
  {
    path: "analysisData/dataVerificationRule",
    name: "dataVerificationRule",
    meta: {
      title: "数据校验规则",
    },
    component: (resolve) =>
      require(["@/views/analysisData/dataSecurity/dataVerificationRule.vue"], resolve),
  },
  // 风险控制总览
  {
    path: "analysisData/riskControl",
    name: "riskControl",
    meta: {
      title: "数据校验规则",
    },
    component: (resolve) =>
      require(["@/views/analysisData/dataSecurity/riskControl.vue"], resolve),
  },
  //资产标准
  {
    path: "analysisData/assetStandard",
    name: "assetStandard",
    meta: {
      title: "资产标准",
    },
    component: (resolve) =>
      require(["@/views/analysisData/assetModel/assetStandard.vue"], resolve),
  },
  //资产标准-新增or编辑
  {
    path: "analysisData/assetStandardForm",
    name: "assetStandardForm",
    meta: {
      title: "",
      echo: "analysisData/assetStandard",
    },
    component: (resolve) =>
      require([
        "@/views/analysisData/assetModel/assetStandardForm.vue",
      ], resolve),
  },

  //汇聚资产
  {
    path: "analysisData/assetConverge",
    name: "assetConverge",
    meta: {
      title: "汇聚资产",
    },
    component: (resolve) =>
      require(["@/views/analysisData/assetModel/assetConverge.vue"], resolve),
  },
  //汇聚资产-编辑
  {
    path: "analysisData/assetConvergeForm",
    name: "assetConvergeForm",
    meta: {
      title: "编辑",
      echo: "analysisData/assetConverge",
    },
    component: (resolve) =>
      require([
        "@/views/analysisData/assetModel/assetConvergeForm.vue",
      ], resolve),
  },
];

export default routes;
