<template>
  <div class="v-control v-steps">
    <el-steps
      :active="active"
      finish-status="success"
      :align-center="alignCenter"
    >
      <el-step
        v-for="(item, index) in stepData"
        :key="index"
        :title="item.title"
      ></el-step>
    </el-steps>
  </div>
</template>

<script>
import Vue from "vue";
import { Steps, Step } from "element-ui";
Vue.use(Steps);
Vue.use(Step);
export default {
  watch: {},
  created() {},
  components: {},
  props: {
    active: {
      type: Number,
    },
    alignCenter: {
      type: Boolean,
      default: true,
    },
    stepData: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="less">
.v-steps {
}
</style>
