<template>
  <el-card :body-style="bodyStyle" class="box-card">
    <template #header>
      <slot name="header"></slot>
    </template>
    <slot></slot>
  </el-card>
</template>

<script>
import Vue from "vue";
import { Card } from "element-ui";
Vue.use(Card);
export default {
  watch: {},
  created() {},
  components: {},
  props: {
    bodyStyle: {
      type: Object,
      default: () => ({
        padding: "20px",
      }),
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="less">
.box-card {
  width: 100%;
  box-sizing: border-box;
}
</style>
