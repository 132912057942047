<template>
  <div class="checkbox-wrapper">
    <div class="common-wrapper">
      <el-checkbox
        class="label"
        :value="value"
        :disabled="disabled"
        :indeterminate="indeterminate"
        @input="onInput"
        @change="onChange"
      >
        {{ label || "" }}
      </el-checkbox>
    </div>
  </div>
</template>

<script>
import { Checkbox } from "element-ui";
// @group 基础组件
// @title Checkbox 选择框
export default {
  name: "v-checkbox",
  components: {
    "el-checkbox": Checkbox,
  },
  props: {
    // 此处一定是value
    value: {
      type: Boolean,
      default: false,
    },
    // 是否可以反选
    canInvert: {
      type: Boolean,
      default: true,
    },
    // 标题
    label: {
      type: String,
      default: "",
    },
    // 循环使用这个组件时,需要传递,其他不需要
    index: {
      type: Number,
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false,
    },
    // 设置 indeterminate 状态，只负责样式控制
    indeterminate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    onInput() {
      if (this.disabled) {
        return;
      }
      if (!this.canInvert) {
        if (this.value) {
          return;
        } else {
          this.$emit("input", true);
        }
      } else {
        this.$emit("input", !this.value);
      }
    },
    onChange() {
      let index = this.index;
      if (index !== undefined) {
        this.$emit("change", this.value, this.index);
      } else {
        this.$emit("change", this.value);
      }
    },
  },
};
</script>

<style scoped lang="less">
.checkbox-wrapper {
  cursor: pointer;
  .common-wrapper {
    display: flex;
    align-items: center;
    .label {
      padding-right: 15px;
    }
  }
}
</style>
