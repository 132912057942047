<template>
  <div class="v-table">
    <el-table
      v-loading="isLoading"
      element-loading-text="数据正在加载中..."
      ref="multipleTable"
      border
      fit
      :height="'100%'"
      :row-key="rowKey"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        v-if="isManySelect"
        type="selection"
        :selectable="selectable"
        :reserve-selection="reserveSelection"
        width="55"
      ></el-table-column>
      <el-table-column
        label="序号"
        v-if="isIndex"
        align="center"
        type="index"
        width="100"
      >
      </el-table-column>
      <template v-for="(header, index) in headers">
        <el-table-column
          v-if="header.show !== false"
          :key="index"
          :prop="header.prop"
          :label="header.label"
          :width="header.width"
          :min-width="header.minWidth"
          :align="header.align"
          :show-overflow-tooltip="
            header.showOverflowTooltip !== undefined
              ? header.showOverflowTooltip
              : showOverflowTooltip
          "
        >
          <template slot-scope="scope">
            <!--传入自定义VNode-->
            <div v-if="header.formatter" class="vnode-wrapper">
              {{
                handleVNodeSlot(
                  `${scope.$index}_${index}_slot`,
                  header.formatter(scope.row, header.prop, scope.$index)
                )
              }}
              <slot :name="`${scope.$index}_${index}_slot`"> </slot>
            </div>
            <!--传入html-->
            <!-- <div style="display: inline-block;" v-else-if="scope.row[header.prop] && scope.row[header.prop].html" v-html="scope.row[header.prop].value"></div> -->
            <!--传入自定义组件-->

            <!--传入普通文本-->
            <div
              v-else
              style="display: inline"
              v-html="scope.row[header.prop]"
            ></div>
          </template>
        </el-table-column>
      </template>
      <el-table-column
        v-if="isOperateColumn"
        :label="operateColumnLabel"
        fixed="right"
        :width="operateColumnWidth"
      >
        <template slot-scope="scope">
          <slot name="operateTeSlot" :row="scope"></slot>
          <slot name="operateSlot" :row="scope.row"></slot>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import Vue from "vue";
import { Table, TableColumn } from "element-ui";
Vue.use(Table);
Vue.use(TableColumn);
export default {
  watch: {},
  created() {},
  components: {},
  props: {
    //仅对 type=selection 的列有效，决定改行 CheckBox 是否可以勾选
    selectable: {
      type: Function,
    },
    // 当内容过长被隐藏时显示 tooltip
    showOverflowTooltip: {
      type: Boolean,
      default: false,
    },
    // 是否显示操作列
    isOperateColumn: {
      type: Boolean,
      default: true,
    },
    isIndex: {
      type: Boolean,
      default: false,
    },
    operateColumnWidth: Number,
    operateColumnLabel: {
      type: String,
      default: "操作",
    },
    isManySelect: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    // 按钮宽度
    width: Number,
    type: {
      type: String,
      default: "primary",
    },
    value: {
      type: String,
      default: "",
    },
    tableData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    headers: {
      type: Array,
      default: function () {
        return [];
      },
    },
    reserveSelection: {
      type: Boolean,
      default: true,
    },
    rowKey: {
      type: [String, Function],
      default() {
        return (row) => {
          return row.id;
        };
      },
    },
  },
  data() {
    return {
      multipleSelection: [],
    };
  },
  methods: {
    getRowKey(row) {
      return row.id;
    },
    handleVNodeSlot(slotName, VNode) {
      this.$slots[slotName] = VNode;
    },
    toggleSelection() {
      this.$refs.multipleTable.clearSelection();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.$emit("selection-change", val);
    },
    // 固定列对 Table 进行重新布局
    doLayout() {
      this.$nextTick(() => {
        this.$refs.multipleTable.doLayout();
        //将滚动条置顶
        this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.v-table {
  height: 100%;
  ::v-deep .opera-btn {
    display: flex;
    flex-wrap: wrap;
    .v-control {
      margin: 0;
    }
    .el-button {
      padding: 4px;
    }
  }
}
.vnode-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
