<template>
  <div class="v-time">
    <el-time-picker
      v-model="model"
      :picker-options="{
        selectableRange: selectableRange,
      }"
      placeholder="任意时间点"
    >
    </el-time-picker>
  </div>
</template>

<script>
import Vue from "vue";
import { TimePicker } from "element-ui";
Vue.use(TimePicker);
export default {
  watch: {
    value(newValue, oldValue) {
      this.changeModel(newValue);
    },
    model(newValue) {
      this.$emit("input", newValue, this);
    },
  },
  created() {},
  components: {},
  props: {
    // 按钮宽度
    width: Number,
    type: {
      type: String,
      default: "date",
    },
    value: Date,
    selectableRange: {
      type: String,
      default: "00:00:00 - 23:59:59",
    },
  },
  data() {
    return {
      model: this.value,
    };
  },
  methods: {
    changeModel(newValue) {
      this.model = newValue;
      // 输入框的值改变时触发
      this.$emit("change", newValue);
    },
    change(value) {
      this.$emit("change", value);
    },
  },
};
</script>
<style lang="less"></style>
