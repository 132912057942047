<template>
  <div class="radio">
    <el-radio-group v-model="radio">
      <div v-for="(item, index) in radioObj" :key="index" class="item">
        <el-radio :label="item.name" @change="change" :disabled="disabled">{{
          item.value
        }}</el-radio>
      </div>
    </el-radio-group>
  </div>
</template>

<script>
import Vue from "vue";
import { Radio, RadioGroup } from "element-ui";
Vue.use(Radio);
Vue.use(RadioGroup);
export default {
  components: {},
  watch: {
    value(newVal) {
      this.radio = newVal;
    },
    // 监听value，如果子组件中的内容变化了，通知父级组件，将新的值告诉父级组件，我更新了，父级组件接受到值后页就跟着变了
    radio(newVal) {
      this.$emit("input", newVal);
    },
  },
  props: {
    radioObj: {
      type: Array,
      default: function () {
        return [{ name: "", value: "" }];
      },
    },
    value: {
      type: [String, Number],
      default: "1",
    },
    direction: {
      type: String,
      default: "horizontal",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      radio: this.value,
    };
  },
  methods: {
    change(value) {
      this.$emit("change", value);
    },
  },
};
</script>

<style lang="less">
.radio {
  height: 100%;
  .el-radio-group {
    display: flex;
  }
  .item {
    margin-right: 10px;
    .el-radio {
      min-height: 36px;
      line-height: 36px;
    }
  }
}
</style>
