import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "@/axios";
import qs from "querystring";
import "./registerServiceWorker";
import watermark from "./utils/watermark";
//element
import {
  Radio,
  RadioGroup,
  RadioButton,
  Checkbox,
  CheckboxGroup,
  CheckboxButton,
  Tag,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Breadcrumb,
  BreadcrumbItem,
  Loading,
  Option,
  Form,
  Row,
  Col,
  FormItem,
  MessageBox,
  Message,
  Dialog,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Image,
  Upload,
  Progress,
  Tabs,
  TabPane,
  Scrollbar,
} from "element-ui";
Vue.use(watermark);
Vue.use(Row);
Vue.use(Col);
Vue.use(Progress);
Vue.use(Upload);
Vue.use(Dialog);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Image);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(CheckboxButton);
Vue.use(Tag);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Option);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Scrollbar);

Vue.use(Loading.directive);
Vue.prototype.$message = Message;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.config.productionTip = false;
Vue.prototype.$qs = qs;
Vue.prototype.$axios = axios;
Vue.prototype.$setBreadList = (options) => {
  store.commit("app/setBreadList", options);
};
Vue.prototype.$clearBreadList = (options) => {
  store.commit("app/clearBreadList", options);
};
//基础组件再封装
import {
  vInput,
  vSelect,
  vButton,
  vTable,
  vPagination,
  vDatePicker,
  vUeditor,
  vUpload,
  vDate,
  vTime,
  vTooltip,
  vRadio,
  vDialog,
  vTabs,
  vCheckbox,
  vInputNumber,
  vCascader,
  vTag,
  vVideoUpload,
  vImageOrVideoUpload,
  vFileUpload,
  vUploadFile,
  vSteps,
  vMap,
  vOldTime,
  vAutocomplete,
  vCard,
  vCheckboxGroup
} from "@/components/control/index";
Vue.component("v-map", vMap);
Vue.component("v-autocomplete", vAutocomplete);
Vue.component("v-steps", vSteps);
Vue.component("v-dialog", vDialog);
Vue.component("v-input", vInput);
Vue.component("v-radio", vRadio);
Vue.component("v-select", vSelect);
Vue.component("v-button", vButton);
Vue.component("v-table", vTable);
Vue.component("v-pagination", vPagination);
Vue.component("v-date-picker", vDatePicker);
Vue.component("v-date", vDate);
Vue.component("v-ueditor", vUeditor);
Vue.component("v-upload", vUpload);
Vue.component("v-videoUpload", vVideoUpload);
Vue.component("v-imageOrVideoUpload", vImageOrVideoUpload);
Vue.component("v-fileUpload", vFileUpload);
Vue.component("v-uploadFile", vUploadFile);
Vue.component("v-time", vTime);
Vue.component("v-tooltip", vTooltip);
Vue.component("v-tabs", vTabs);
Vue.component("v-checkbox", vCheckbox);
Vue.component("v-input-number", vInputNumber);
Vue.component("v-cascader", vCascader);
Vue.component("v-tag", vTag);
Vue.component("v-time-old", vOldTime);
Vue.component("v-card", vCard);
Vue.component("v-checkboxGroup", vCheckboxGroup);
import PreviewBox from "@/components/control/v-preview/preview";
Vue.prototype.$previewBox = PreviewBox;
//业务组件
import oList from "@/components/bussiness/list/index.vue";
import formPanel from "@/components/bussiness/form-panel/index.vue";
import communitySelect from "@/components/bussiness/communitySelect/index.vue";
import areaSelect from "@/components/bussiness/areaSelect/index.vue";
Vue.component("area-select", areaSelect);
Vue.component("community-select", communitySelect);
Vue.component("v-list", oList);
Vue.component("form-panel", formPanel);

export const vue = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

/**
 * 对Date的扩展，将 Date 转化为指定格式的String
 * 月(M)、日(d)、12小时(h)、24小时(H)、分(m)、秒(s)、周(E)、季度(q) 可以用 1-2 个占位符
 * 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
 * eg:
 * (new Date()).pattern("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423
 * (new Date()).pattern("yyyy-MM-dd E HH:mm:ss") ==> 2009-03-10 二 20:09:04
 * (new Date()).pattern("yyyy-MM-dd EE hh:mm:ss") ==> 2009-03-10 周二 08:09:04
 * (new Date()).pattern("yyyy-MM-dd EEE hh:mm:ss") ==> 2009-03-10 星期二 08:09:04
 * (new Date()).pattern("yyyy-M-d h:m:s.S") ==> 2006-7-2 8:9:4.18
 */
Date.prototype.format = function (fmt) {
  let o = {
    "M+": this.getMonth() + 1, //月份
    "d+": this.getDate(), //日
    "h+": this.getHours() % 12 == 0 ? 12 : this.getHours() % 12, //小时
    "H+": this.getHours(), //小时
    "m+": this.getMinutes(), //分
    "s+": this.getSeconds(), //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds(), //毫秒
  };
  let week = {
    0: "/u65e5",
    1: "/u4e00",
    2: "/u4e8c",
    3: "/u4e09",
    4: "/u56db",
    5: "/u4e94",
    6: "/u516d",
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (this.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  if (/(E+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (RegExp.$1.length > 1
        ? RegExp.$1.length > 2
          ? "/u661f/u671f"
          : "/u5468"
        : "") + week[this.getDay() + ""]
    );
  }
  for (let k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  }
  return fmt;
};
