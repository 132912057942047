const state = {
  sidebar: {
    opened: true,
  },
  // 记录用户信息
  userInfo: {},
  // 为 menuList 中的对象添加字段 - App.vue
  formatMenu: [],
  // 将 formatMenu 数组进行扁平化处理
  flatMenu: [],
  // 二级页面的面包屑
  breadList: [],
  menuLimit: {},
  levelList: [{ title: "", id: "0" }],
  /*
    规则：
      1. 文件夹不含path
      2. 路由节点path为全hash路由
      3. id,path 必须唯一
  */
  menuList: [],
  // menuList: [
  //   {
  //     id: "1",
  //     path: "",
  //     title: "未来服务",
  //     icon: "",
  //     level: 1,
  //     url: "applyActivity",
  //     children: [
  //       {
  //         id: "1-0",
  //         path: "",
  //         title: "活动管理",
  //         level: 2,
  //         level1Id: 1,
  //         icon: "",
  //         type: 1,
  //         url: "applyActivity",
  //         children: [
  //           {
  //             id: "1-1-1",
  //             path: "futureNeighbourhood/applyActivity",
  //             title: "活动列表",
  //             level: 3,
  //             type: 1,
  //             level1Id: 1,
  //             level2Id: "1-0",
  //             url: "applyActivity",
  //             icon: "",
  //           },
  //           {
  //             id: "1-1-2",
  //             path: "futureNeighbourhood/activityAuditList",
  //             title: "活动审核列表",
  //             level: 3,
  //             type: 1,
  //             level1Id: 1,
  //             level2Id: "1-1",
  //             url: "activityAuditList",
  //             icon: "",
  //           },
  //         ],
  //       },
  //       {
  //         id: "1-2",
  //         level: 2,
  //         level1Id: 2,
  //         type: 1,
  //         path: "",
  //         title: "场地管理",
  //         icon: "",
  //         url: "placeMng",
  //         children: [
  //           {
  //             id: "1-2-1",
  //             level: 2,
  //             level1Id: 2,
  //             type: 1,
  //             path: "futureService/placeTypeList",
  //             title: "场地类型管理",
  //             url: "placeTypeList",
  //             icon: "",
  //             children: [],
  //           },
  //           {
  //             id: "1-2-2",
  //             level: 2,
  //             level1Id: 2,
  //             type: 1,
  //             path: "futureService/placeList",
  //             title: "场地管理",
  //             url: "placeList",
  //             icon: "",
  //             children: [],
  //           },
  //           {
  //             id: "1-2-3",
  //             level: 2,
  //             level1Id: 2,
  //             type: 1,
  //             path: "futureService/placeOrderList",
  //             title: "场地订单",
  //             url: "placeOrderList",
  //             icon: "",
  //             children: [],
  //           },
  //         ],
  //       },
  //       {
  //         id: "1-3",
  //         level: 2,
  //         level1Id: 2,
  //         type: 1,
  //         path: "",
  //         title: "公益捐赠",
  //         icon: "",
  //         url: "donateManage",
  //         children: [
  //           {
  //             id: "1-3-1",
  //             level: 2,
  //             level1Id: 2,
  //             type: 1,
  //             path: "futureNeighbourhood/donateList",
  //             title: "捐赠列表",
  //             url: "donateList",
  //             icon: "",
  //           },
  //         ],
  //       },
  //       {
  //         id: "1-4",
  //         path: "",
  //         title: "积分商城",
  //         level: 2,
  //         level1Id: 1,
  //         icon: "",
  //         type: 1,
  //         url: "goodsList",
  //         children: [
  //           {
  //             id: "1-4-1",
  //             path: "futureService/goodsList",
  //             title: "商品列表",
  //             level: 3,
  //             type: 1,
  //             level1Id: 1,
  //             level2Id: "1-0",
  //             url: "goodsList",
  //             icon: "",
  //             children: [],
  //           },
  //           {
  //             id: "1-4-2",
  //             path: "futureService/pointsMallOrderList",
  //             title: "兑换订单列表",
  //             level: 3,
  //             type: 1,
  //             level1Id: 1,
  //             level2Id: "1-0",
  //             url: "pointsMallOrderList",
  //             icon: "",
  //             children: [],
  //           },
  //         ],
  //       },
  //       {
  //         id: "1-5",
  //         path: "",
  //         title: "小和互动",
  //         level: 2,
  //         level1Id: 1,
  //         icon: "",
  //         type: 1,
  //         url: "interaction",
  //         children: [
  //           {
  //             id: "1-5-1",
  //             path: "futureNeighbourhood/interaction",
  //             title: "小和互动列表",
  //             level: 3,
  //             type: 1,
  //             level1Id: 1,
  //             level2Id: "1-5",
  //             url: "interaction",
  //             icon: "",
  //             children: [],
  //           },
  //         ],
  //       },
  //       {
  //         id: "1-6",
  //         path: "",
  //         title: "小和广播站",
  //         level: 2,
  //         level1Id: 1,
  //         icon: "",
  //         type: 1,
  //         url: "broadcastList",
  //         children: [
  //           {
  //             id: "1-6-1",
  //             path: "futureService/broadcastList",
  //             title: "小和广播站",
  //             level: 3,
  //             type: 1,
  //             level1Id: 1,
  //             level2Id: "1-6",
  //             url: "broadcastList",
  //             icon: "",
  //             children: [],
  //           },
  //         ],
  //       },
  //       {
  //         id: "1-7",
  //         path: "",
  //         title: "运营位管理",
  //         level: 2,
  //         level1Id: 1,
  //         icon: "",
  //         type: 1,
  //         url: "bannerList",
  //         children: [
  //           {
  //             id: "1-7-1",
  //             path: "other/bannerList",
  //             title: "运营位管理",
  //             level: 3,
  //             type: 1,
  //             level1Id: 1,
  //             level2Id: "1-7",
  //             url: "bannerList",
  //             icon: "",
  //             children: [],
  //           },
  //         ],
  //       },
  //       {
  //         id: "1-8",
  //         path: "",
  //         title: "问卷调查",
  //         level: 2,
  //         level1Id: 1,
  //         icon: "",
  //         type: 1,
  //         url: "",
  //         children: [
  //           {
  //             id: "1-8-1",
  //             path: "futureService/questionnaireList",
  //             title: "问卷列表",
  //             level: 3,
  //             type: 1,
  //             level1Id: 1,
  //             level2Id: "1-0",
  //             url: "questionnaireList",
  //             icon: "",
  //           },
  //           // {
  //           //   id: "1-8-2",
  //           //   path: "futureService/activityAuditList",
  //           //   title: "活动审核列表",
  //           //   level: 3,
  //           //   type: 1,
  //           //   level1Id: 1,
  //           //   level2Id: "1-1",
  //           //   url: "activityAuditList",
  //           //   icon: "",
  //           // },
  //         ],
  //       },
  //     ],
  //   },
  //   {
  //     id: "2",
  //     path: "",
  //     title: "未来治理",
  //     icon: "",
  //     level: 1,
  //     url: "govern",
  //     children: [
  //       {
  //         id: "2-1",
  //         level: 2,
  //         level1Id: 2,
  //         type: 1,
  //         path: "",
  //         title: "心愿墙管理",
  //         icon: "",
  //         url: "wishWallMng",
  //         children: [
  //           {
  //             id: "2-1-1",
  //             level: 2,
  //             level1Id: 2,
  //             type: 1,
  //             path: "futureNeighbourhood/wishWallList",
  //             title: "心愿墙列表",
  //             url: "wishWallList",
  //             icon: "",
  //             children: [],
  //           },
  //         ],
  //       },
  //       {
  //         id: "2-2",
  //         level: 2,
  //         level1Id: 2,
  //         type: 1,
  //         path: "",
  //         title: "社区共建管理",
  //         icon: "",
  //         url: "communityConstructionMng",
  //         children: [
  //           {
  //             id: "2-2-1",
  //             level: 2,
  //             level1Id: 2,
  //             type: 1,
  //             path: "futureNeighbourhood/communityConstructionList",
  //             title: "社区共建列表",
  //             url: "communityConstructionList",
  //             icon: "",
  //             children: [],
  //           },
  //         ],
  //       },
  //     ],
  //   },
  //   {
  //     id: "3",
  //     path: "",
  //     title: "运营设置",
  //     icon: "",
  //     type: 1,
  //     level: 1,
  //     url: "service",
  //     children: [
  //       {
  //         id: "3-1",
  //         level: 2,
  //         level1Id: 2,
  //         type: 1,
  //         path: "futureService/bonusPointSettingList",
  //         title: "积分规则配置",
  //         icon: "",
  //         url: "bonusPointSettingList",
  //         children: [],
  //       },
  //       {
  //         id: "3-2",
  //         level: 2,
  //         level1Id: 2,
  //         type: 1,
  //         path: "futureNeighbourhood/talentApplyList",
  //         title: "社区达人申请列表",
  //         icon: "",
  //         url: "talentApplyList",
  //         children: [],
  //       },
  //     ],
  //   },
  //   {
  //     id: "4",
  //     path: "",
  //     title: "未来邻里",
  //     icon: "",
  //     type: 1,
  //     level: 1,
  //     url: "",
  //     children: [
  //       {
  //         id: "4-1",
  //         level: 2,
  //         level1Id: 2,
  //         type: 1,
  //         path: "futureNeighbourhood/partnerList",
  //         title: "社区达人风采",
  //         icon: "",
  //         url: "partnerList",
  //         children: [],
  //       },
  //       {
  //         id: "4-2",
  //         level: 2,
  //         level1Id: 2,
  //         type: 1,
  //         path: "futureNeighbourhood/topicList",
  //         title: "小和超话区",
  //         icon: "",
  //         url: "topicList",
  //         children: [],
  //       },
  //       {
  //         id: "4-3",
  //         path: "",
  //         title: "社群管理",
  //         level: 2,
  //         level1Id: 2,
  //         icon: "",
  //         type: 1,
  //         url: "association",
  //         children: [
  //           {
  //             id: "4-3-1",
  //             path: "futureNeighbourhood/association",
  //             title: "社群列表",
  //             level: 3,
  //             type: 1,
  //             level1Id: 4,
  //             level2Id: "4-3",
  //             url: "association",
  //             icon: "",
  //           },
  //         ],
  //       },
  //     ],
  //   },
  //   {
  //     id: "5",
  //     path: "",
  //     title: "未来健康",
  //     icon: "",
  //     type: 1,
  //     level: 1,
  //     url: "",
  //     children: [
  //       {
  //         id: "5-1",
  //         level: 2,
  //         level1Id: 2,
  //         type: 1,
  //         path: "futureHealth/recordSport",
  //         title: "运动打卡",
  //         icon: "",
  //         url: "recordSport",
  //         children: [],
  //       },
  //     ],
  //   },
  //   {
  //     id: "6",
  //     path: "",
  //     title: "系统设置",
  //     icon: "",
  //     type: 1,
  //     level: 1,
  //     url: "service",
  //     children: [
  //       {
  //         id: "6-1",
  //         level: 2,
  //         level1Id: 2,
  //         type: 1,
  //         path: "setSystem/accountList",
  //         title: "账号管理",
  //         icon: "",
  //         url: "accountList",
  //         children: [],
  //       },
  //       {
  //         id: "6-2",
  //         level: 2,
  //         level1Id: 2,
  //         type: 1,
  //         path: "setSystem/roleList",
  //         title: "角色管理",
  //         icon: "",
  //         url: "roleList",
  //         children: [],
  //       },
  //     ],
  //   },
  // ],
};

const mutations = {
  setMenuLimit: (state, value) => {
    state.menuLimit = value;
  },
  setMenu: (state, value) => {
    state.menuList = value;
  },
  setLevelList: (state, value) => {
    state.levelList = value;
  },
  TOGGLE_SIDEBAR: (state) => {
    state.sidebar.opened = !state.sidebar.opened;
  },
  setUserInfo: (state, value) => {
    state.userInfo = value;
  },
  setFormatMenu: (state, value) => {
    state.formatMenu = value;
  },
  setFlatMenu: (state, value) => {
    state.flatMenu = value;
  },
  /* 该方法可传数组，也可传字符串
   * 仅用于二级页面——未存储在 menuList 状态内的路由页面
   * 最好在.vue页面的 creaetd 生命周期调用
   */
  setBreadList: (state, value) => {
    console.log("触发了");
    if (typeof value === "object") {
      // 如果是数组，格式为 [{ title: 'a' }, ..., { title: 'b' }]
      state.breadList = value.map((v, i) => ({
        title: v.title,
        id: `add_0${i}`,
      }));
    } else if (typeof value === "string") {
      // 如果是字符串
      state.breadList = [{ title: value, id: "add_01" }];
    }
  },
  clearBreadList: (state) => {
    state.breadList = [];
  },
};

const actions = {
  toggleSideBar({ commit }) {
    commit("TOGGLE_SIDEBAR");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
