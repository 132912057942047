<template>
  <div class="v-control v-autocomplete">
    <label v-if="label" v-text="label"></label>
    <el-autocomplete
      v-model="model"
      :fetch-suggestions="querySearchAsync"
      placeholder="请输入内容"
      @select="handleSelect"
    ></el-autocomplete>
  </div>
</template>

<script>
import Vue from "vue";
import { Autocomplete } from "element-ui";
Vue.use(Autocomplete);
export default {
  props: {
    querySearchAsync: Function,
    label: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      inputWidth: this.width,
      model: this.value,
    };
  },
  watch: {
    value(newValue, oldValue) {
      this.changeModel(newValue);
    },
    model(newValue) {
      this.$emit("input", newValue, this);
    },
  },
  created() {
    this.bind = {
      style: {
        width: this.inputWidth + "px",
      },
    };
  },
  methods: {
    reset() {
      this.model = "";
    },
    clear() {
      this.$emit("clear");
    },
    focus() {
      this.$emit("focus");
    },
    blur() {
      this.$emit("blur");
    },
    keyup() {
      this.$emit("keyup");
    },
    handleSelect(newValue) {
      this.model = newValue.value;
      // 输入框的值改变时触发
      this.$emit("change", newValue);
    },
  },
};
</script>

<style lang="less">
.v-autocomplete {
  label {
    font-size: 14px;
    columns: #999;
    margin-right: 10px;
  }
}
</style>
