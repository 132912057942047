const routes = [
  {
    path: "futureHealth/recordSport",
    name: "recordSport",
    meta: {
      title: "运动打卡",
    },
    component: (resolve) =>
      require(["@/views/futureHealth/recordSport/index.vue"], resolve),
  },
  {
    path: "setSystem/equipmentApply",
    name: "equipmentApply",
    meta: {
      title: "设备申请",
    },
    component: (resolve) =>
      require(["@/views/futureHealth/equipmentApply/index.vue"], resolve),
  },
  // 防疫管理
  {
    path: "futureHealth/accurateAntiepidemic",
    name: "accurateAntiepidemic",
    meta: {
      title: "防疫通知",
    },
    component: (resolve) =>
      require(["@/views/futureHealth/accurateAntiepidemic/index.vue"], resolve),
  },
  {
    path: "futureHealth/notConnectedList",
    name: "notConnectedList",
    meta: {
      title: "未接通人员数据",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/accurateAntiepidemic/notConnectedList.vue",
      ], resolve),
  },
  {
    path: "futureHealth/AIHistory",
    name: "AIHistory",
    meta: {
      title: "AI回访历史",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/accurateAntiepidemic/AIHistory.vue",
      ], resolve),
  },
  {
    path: "futureHealth/SMSHistory",
    name: "SMSHistory",
    meta: {
      title: "短信历史",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/accurateAntiepidemic/SMSHistory.vue",
      ], resolve),
  },
  //康养地图场馆总览
  {
    path: "futureHealth/venueOverview",
    name: "venueOverview",
    meta: {
      title: "康养地图总览",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/venueOverview/index.vue",
      ], resolve),
  },
];

export default routes;
