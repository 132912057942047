<template>
  <div class="v-pagination">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="curPage"
      :page-sizes="pageSizes"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import Vue from "vue";
import { Pagination } from "element-ui";
Vue.use(Pagination);
export default {
  watch: {},
  created() {},
  components: {},
  props: {
    pageSizes: {
      type: Array,
      default: function () {
        return [10, 20, 30, 40];
      },
    },
    pageSize: {
      type: Number,
      default: 20,
    },
    curPage: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 20,
    },
    // 按钮宽度
    width: Number,
    type: {
      type: String,
      default: "primary",
    },
    value: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    handleSizeChange(val) {
      this.$emit("handleSizeChange", val);
    },
    handleCurrentChange(val) {
      this.$emit("handleCurrentChange", val);
    },
  },
};
</script>
<style lang="less"></style>
