<template>
  <div class="v-control v-input-number-container">
    <label v-if="label" v-text="label"></label>
    <el-input-number
      v-model="model"
      :placeholder="defaultPlaceholder"
      :disabled="disabled"
      :style="{ width: width + 'px', textAlign: textAlign }"
      :controls="controls"
      :controls-position="controlsPosition"
      :precision="precision"
      :max="max"
      :min="min"
      :step="step"
      @blur="onBlur"
      @focus="onFocus"
      @change="onChange"
    />
  </div>
</template>

<script>
import Vue from "vue";
import { InputNumber } from "element-ui";
import emitter from "element-ui/src/mixins/emitter";

Vue.use(InputNumber);

// @group 基础组件
// @title InputNumber 数字输入框
export default {
  name: "v-input-number",
  mixins: [emitter],
  props: {
    // 绑定值
    value: [String, Number],
    // 数字输入框左侧label
    label: String,
    // 数字输入框占位文本
    placeholder: String,
    // 是否禁用
    disabled: Boolean,
    // 数字输入框宽度
    width: {
      type: Number,
      // `144`
      default: 144,
    },
    // 是否使用控制按钮
    controls: {
      type: Boolean,
      // `false`
      default: false,
    },
    // 控制按钮位置
    controlsPosition: {
      // `'right'`
      type: String,
    },
    // 精度
    precision: {
      type: Number,
      // `0`
      default: 0,
    },
    // 数字输入框允许的最大值
    max: Number,
    // 数字输入框允许的最小值
    min: {
      type: Number,
      // `0`
      default: 0,
    },
    // 计数器步长
    step: {
      type: Number,
      // `1`
      default: 1,
    },
  },
  data() {
    return {
      model: this.value,
      defaultPlaceholder: this.placeholder,
      textAlign: "left",
    };
  },
  created() {
    if (this.placeholder === undefined && this.label) {
      this.defaultPlaceholder = `请输入${this.label}`;
    }
    if (this.controls && !this.controlsPosition) {
      this.textAlign = "center";
    }
  },
  watch: {
    value(newValue) {
      if (newValue === "") {
        this.model = undefined;
      } else {
        this.model = newValue;
      }
    },
    model(newValue) {
      // v-model event
      this.$emit("input", newValue);
    },
  },
  methods: {
    onBlur(event) {
      // 数字输入框失去焦点时触发
      // @arg event
      this.$emit("blur", event);
      this.$nextTick(() => {
        this.dispatch("ElFormItem", "el.form.blur", [this.model]);
      });
    },
    onChange(event) {
      // 数字输入框失去焦点时触发
      // @arg event
      this.$emit("change", event);
      this.$nextTick(() => {
        this.dispatch("ElFormItem", "el.form.blur", [this.model]);
      });
    },
    onFocus(event) {
      // 数字输入框获得焦点时触发
      // @arg event
      this.$emit("focus", event);
    },
  },
};
</script>
<style scoped lang="less">
.v-input-number-container {
  display: inline-block;
  ::v-deep .el-input {
    .el-input__inner {
      padding: 0 14px;
      text-align: inherit;
    }
  }
  label {
    margin-right: 5px;
    color: #333333;
  }
}
</style>
