import axios from "axios";
import store from "../store";
import router from "../router";
import { Message } from "element-ui";
const header = {
  timeout: 200000,
  headers: {
    Authorization: store.state.authorization,
  },
};

const _axios = axios.create(header);

_axios.interceptors.request.use(
  (config) => {
    // console.log(config, "config---");
    let auth = store.state.app.userInfo.accessToken;
    let type = store.state.app.userInfo.tokenType;
    let headersParams = {};
    if (auth) {
      headersParams["hc-auth"] = `${type} ${auth}`;
    }
    if (config.url.includes('/oss/endpoint/put-file')) {
      headersParams.userId = store.state.app.userInfo.userId;
      headersParams.tenantId = store.state.app.userInfo.tenantId;
      headersParams.uploadFrom = 1;
    }
    config.headers = Object.assign({ ...headersParams }, config.headers);
    return config;
  },
  function (error) {
    console.log(error, "error---");
    return Promise.reject(error);
  }
);

_axios.interceptors.response.use(
  async (res) => {
    // 1. 用于判断是否流形式的导出接口
    if (
      res &&
      res.headers &&
      res.headers["content-type"] &&
      /application\/vnd.ms-excel/.test(res.headers["content-type"])
    ) {
      if (res.status === 200) {
        return res;
      } else {
        return Promise.reject(res);
      }
    }
    // 2. 普通接口处理方式
    if (res.status !== 200) {
      return Promise.reject(res);
    } else {
      if (res.data.code != 200) {
        Message(res.data.msg || "请求错误！");
      }
    }
    return res.data;
  },
  async (error) => {
    if (error.response && error.response.status === 502) {
      Message.error("服务器错误！");
      return;
    }
    if (error.response && error.response.status === 401) {
      if (error.response.data.msg == "超时退出登录") {
        sessionStorage.clear();
        router.push({
          name: "login",
        });
        Message.error(error.response.data.msg);
        return;
      } else if (error.response.data.msg == "令牌过期") {
        //无感刷新token
        try {
          let config = error.config;
          await store.dispatch("refreshToken");
          let auth = store.state.app.userInfo.accessToken;
          let type = store.state.app.userInfo.tokenType;
          config.headers["hc-auth"] = `${type} ${auth}`;
          return _axios(config);
        } catch (error) {
          router.push({
            name: "login",
          });
        }
      } else {
        router.push({
          name: "login",
        });
      }
    }
    return error;
  }
);

export default _axios;
