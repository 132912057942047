const routes = [
  {
    path: "futureCreateWork/createWorkTalent",
    name: "createWorkTalent",
    meta: {
      title: "创业达人",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/createWorkTalent/index.vue"], resolve),
  },
  {
    path: "futureCreateWork/createWorkTalentForm",
    name: "createWorkTalentForm",
    meta: {
      echo: "futureCreateWork/createWorkTalent",
      title: "创业达人",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/createWorkTalent/createWorkTalentForm.vue",
      ], resolve),
  },
  //创业导师
  {
    path: "futureCreateWork/createWorkTeacher",
    name: "createWorkTeacher",
    meta: {
      title: "创业导师",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/createWorkTeacher/index"], resolve),
  },
  //新增创业导师
  {
    path: "futureCreateWork/createWorkTeacherForm",
    name: "createWorkTeacherForm",
    meta: {
      title: "创业导师",
      echo: "futureCreateWork/createWorkTeacher",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/createWorkTeacher/createWorkTeacherForm",
      ], resolve),
  },

  //服务联盟
  {
    path: "futureCreateWork/serviceLeague",
    name: "serviceLeague",
    meta: {
      title: "服务联盟",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/serviceLeague/index"], resolve),
  },
  //新增服务联盟
  {
    path: "futureCreateWork/serviceLeagueForm",
    name: "serviceLeagueForm",
    meta: {
      title: "新增服务联盟",
      echo: "futureCreateWork/serviceLeague",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/serviceLeague/serviceLeagueForm",
      ], resolve),
  },
  //租赁场所
  {
    path: "futureCreateWork/rentPlace",
    name: "rentPlace",
    meta: {
      title: "租赁场所",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/rentPlace/index"], resolve),
  },
  //租赁场所详情
  {
    path: "futureCreateWork/rentPlaceDetail",
    name: "rentPlaceDetail",
    meta: {
      title: "租赁场所详情",
      echo: "futureCreateWork/rentPlace",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/rentPlace/rentPlaceDetail"], resolve),
  },
  //租赁订单
  {
    path: "futureCreateWork/rentPlaceOrder",
    name: "rentPlaceOrder",
    meta: {
      title: "租赁订单",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/rentPlace/rentPlaceOrder"], resolve),
  },
  //租赁订单详情
  {
    path: "futureCreateWork/rentPlaceOrderForm",
    name: "rentPlaceOrderForm",
    meta: {
      title: "租赁订单详情",
      echo: "futureCreateWork/rentPlaceOrder",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/rentPlace/rentPlaceOrderForm",
      ], resolve),
  },
  //楼宇管理
  {
    path: "futureCreateWork/buildingManagement",
    name: "buildingManagement",
    meta: {
      title: "楼宇管理",
      echo: "futureCreateWork/buildingManagement",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/buildingManagement/index.vue",
      ], resolve),
  },
  {
    path: "futureCreateWork/businessPage",
    name: "businessPage",
    meta: {
      title: "营业收入",
      echo: "futureCreateWork/businessPage",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/buildingManagement/businessPage.vue",
      ], resolve),
  },
  {
    path: "futureCreateWork/taxSourcePage",
    name: "taxSourcePage",
    meta: {
      title: "总税收",
      echo: "futureCreateWork/taxSourcePage",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/buildingManagement/taxSourcePage.vue",
      ], resolve),
  },
  {
    path: "futureCreateWork/visitorsPage",
    name: "visitorsPage",
    meta: {
      title: "走访记录",
      echo: "futureCreateWork/visitorsPage",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/buildingManagement/visitorsPage.vue",
      ], resolve),
  },
  {
    path: "futureCreateWork/visitors",
    name: "visitors",
    meta: {
      title: "走访人员",
      echo: "futureCreateWork/visitors",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/buildingManagement/visitors.vue",
      ], resolve),
  },
  {
    path: "futureCreateWork/importBuilding",
    name: "importBuilding",
    meta: {
      title: "导入信息",
      echo: "futureCreateWork/importBuilding",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/buildingManagement/importBuilding.vue",
      ], resolve),
  },
  {
    path: "futureCreateWork/buildingManagementForm",
    name: "buildingManagementForm",
    meta: {
      title: "编辑",
      echo: "futureCreateWork/buildingManagementForm",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/buildingManagement/buildingManagementForm.vue",
      ], resolve),
  },
  //  531  start ----------------------------------------------------------------
  // 问卷调查-start
  {
    path: "futureCreateWork/questionnaireSurvey",
    name: "questionnaireSurvey",
    meta: {
      title: "问卷调查列表",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/questionnaireSurvey/questionnaireList.vue",
      ], resolve),
  },
  {
    path: "futureCreateWork/questionnaireEdit",
    name: "questionnaireSurveyEdit",
    meta: {
      title: "问卷调查表单",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/questionnaireSurvey/questionnaireEdit.vue",
      ], resolve),
  },
  {
    path: "futureCreateWork/questionAnswerList",
    name: "questionnaireSurveyAnswerList",
    meta: {
      title: "参与人列表",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/questionnaireSurvey/questionAnswerList.vue",
      ], resolve),
  },
  // 问卷调查-end
  // 投票管理-start
  {
    path: "futureCreateWork/votePage",
    name: "votePage",
    meta: {
      title: "投票",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/votePage/index.vue"], resolve),
  },
  {
    path: "futureCreateWork/votePeopleList",
    name: "votePagePeopleList",
    meta: {
      title: "投票参与人",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/votePage/votePeopleList.vue",
      ], resolve),
  },
  {
    path: "futureCreateWork/voteForm",
    name: "votePageForm",
    meta: {
      title: "投票",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/votePage/voteForm.vue"], resolve),
  },
  // 投票管理-end

  // 咨询发布-start
  {
    path: "futureCreateWork/consultList",
    name: "consultList",
    meta: {
      title: "咨询发布",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/consult/consultList.vue"], resolve),
  },

  // 咨询发布新增/编辑
  {
    path: "futureCreateWork/consultForm",
    name: "consultForm",
    meta: {
      title: "咨询发布",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/consult/consultForm.vue"], resolve),
  },

  //咨询发布-end

  // 选举后台-start
  {
    path: "futureCreateWork/voteBackstage",
    name: "voteBackstage",
    meta: {
      title: "选举后台",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/voteBackstage/index.vue"], resolve),
  },
  // 选举后台新增/编辑
  {
    path: "futureCreateWork/voteBackstageForm",
    name: "voteBackstageForm",
    meta: {
      title: "选举后台",
      echo: "futureCreateWork/voteBackstage",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/voteBackstage/voteBackstageForm.vue",
      ], resolve),
  },
  // 选举后台编辑步骤
  {
    path: "futureCreateWork/voteBackstageStep",
    name: "voteBackstageStep",
    meta: {
      title: "编辑步骤",
      echo: "futureCreateWork/voteBackstage",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/voteBackstage/voteBackstageStep.vue",
      ], resolve),
  },
  // 查看
  {
    path: "futureCreateWork/voteBackstageExamine",
    name: "voteBackstageExamine",
    meta: {
      title: "查看",
      echo: "futureCreateWork/voteBackstage",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/voteBackstage/voteBackstageExamine.vue",
      ], resolve),
  },

  // 选举后台-end
  {
    path: "futureCreateWork/sinkPartyMember",
    name: "sinkPartyMember",
    meta: {
      title: "下沉党员",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/sinkPartyMember/index.vue"], resolve),
  },
  // 531 end --------------------------------
  // 抽奖管理
  {
    path: "futureCreateWork/luckDrawList",
    name: "luckDrawList",
    meta: {
      title: "抽奖管理",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/luckDraw/luckDrawList.vue"], resolve),
  },
  // 抽奖管理-新增/编辑
  {
    path: "futureCreateWork/luckDrawForm",
    name: "luckDrawForm",
    meta: {
      echo: "futureCreateWork/luckDrawList",
      title: "抽奖管理",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/luckDraw/luckDrawForm.vue"], resolve),
  },
  // 抽奖管理-报名人员
  {
    path: "futureCreateWork/luckDrawApplicantList",
    name: "luckDrawApplicantList",
    meta: {
      echo: "futureCreateWork/luckDrawList",
      title: "报名人员",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/luckDraw/luckDrawApplicantList.vue",
      ], resolve),
  },
];

export default routes;
