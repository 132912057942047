<template>
  <div class="checkbox-group">
    <el-checkbox-group v-model="selectedValues">
      <el-checkbox
        v-for="option in options"
        :label="option.value"
        :key="option.value"
        :disabled="option.isDisabled"
      >
        {{ option.label }}
      </el-checkbox>
    </el-checkbox-group>
  </div>
</template>

<script>
import { Checkbox, CheckboxGroup } from "element-ui";
export default {
  name: "checkbox-group",
  components: {
    "el-checkbox-group": CheckboxGroup,
    "el-checkbox": Checkbox,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  computed: {
    selectedValues: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less"></style>
