const downloadHelper = {
  downloadBySrc(config) {
    const a = document.createElement("a");
    a.href = config.src;
    if (config.target) {
      a.target = config.target;
    }
    a.download = config.fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  },
  downloadByLocation(filePath) {
    if (filePath.substring(0, 1) === "/") {
      window.location.href = `${location.origin}${location.pathname.substr(
        0,
        location.pathname.lastIndexOf("/")
      )}${filePath}`;
    } else {
      window.location.href = filePath;
    }
  },
};
export { downloadHelper };
