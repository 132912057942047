<template>
  <div class="v-control v-cascader-wrapper">
    <label v-if="label" v-text="label"></label>
    <el-cascader
      ref="elCascader"
      :popper-class="extraClass"
      :value="value"
      :style="{ width: width + 'px' }"
      :clearable="clearable"
      :options="options"
      :props="props"
      :placeholder="placeholder"
      :disabled="disabled"
      :expand-trigger="expandTrigger"
      :filterable="filterable"
      :change-on-select="changeOnSelect"
      :separator="separator"
      :collapse-tags="collapseTags"
      @input="onInput"
      @change="change"
      @expand-change="expandChange"
      @visible-change="visibleChange"
    >
      <template slot-scope="{ node, data }">
        <slot :node="node" :data="data"></slot>
      </template>
    </el-cascader>
  </div>
</template>

<script>
import Vue from "vue";
import { Cascader } from "element-ui";

Vue.use(Cascader);

// @group 基础组件
// @title Cascader 级联选择器
export default {
  name: "v-cascader",
  props: {
    label: {
      type: String,
      default: "",
    },
    // 选中项的value数组，支持`.sync`
    value: Array,
    // 可选项数据源，键名可通过 props 属性配置
    options: {
      type: Array,
      required: true,
      // `[]`
      default: () => {
        return [];
      },
    },
    // props 配置选项，具体见Cascader的props说明 ` https://element.eleme.cn/#/zh-CN/component/cascader `
    props: {
      type: Object,
    },
    // 额外class类名
    extraClass: {
      type: String,
      default: "",
    },
    // 选项分隔符
    separator: {
      type: String,
      // `横杠'-'`
      default: ",",
    },
    // 输入框占位文本
    placeholder: String,
    // 是否禁用
    disabled: {
      type: Boolean,
      // `false`
      default: false,
    },
    // 是否显示清除按钮
    clearable: {
      type: Boolean,
      // `true`
      default: true,
    },
    // 显示宽度
    width: {
      type: Number,
      // `160`
      default: 160,
    },
    // 次级菜单的展开方式 可选值 `click / hover`
    expandTrigger: {
      type: String,
      // `click`
      default: "click",
    },
    // 是否可搜索选项
    filterable: {
      type: Boolean,
      // `false`
      default: false,
    },
    // 是否允许选择任意一级的选项
    changeOnSelect: {
      type: Boolean,
      // `false`
      default: false,
    },
    // 折叠显示选中的标签tag
    collapseTags: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // 获取props中value对应的key
      propsValueKey: this.getPropsValueKey(),
      // 获取props中children对应的key
      propsChildrenKey: this.getPropsChildrenKey(),
      // model: this.value,
      // sourceOps: this.options || []
    };
  },
  methods: {
    // 获取props中value对应的key
    getPropsValueKey() {
      let key = "value";
      if (this.props) {
        key = this.props.value ? this.props.value : key;
      }
      return key;
    },

    // 获取props中children对应的key
    getPropsChildrenKey() {
      let key = "children";
      if (this.props) {
        key = this.props.children ? this.props.children : key;
      }
      return key;
    },
    onInput(val) {
      this.$emit("input", val);
    },
    change(values) {
      // 当绑定值变化时触发的事件， 返回当前所选值的对象数组
      this.$emit("change", values);
    },

    expandChange(values) {
      // 当展开节点发生变化时触发
      this.$emit("expand-change", values);
    },

    visibleChange(visible) {
      // 下拉框出现/隐藏时触发，返回值 出现则为 true，隐藏则为 false
      this.$emit("visible-change", visible);
    },

    // 获取选中的节点
    getCheckedNodes() {
      return this.$refs.elCascader.getCheckedNodes();
    },

    // 根据key，获取选择的对象数组
    getSelectData(values) {
      let selectList = [];
      let len = values.length;
      if (len && this.options.length) {
        let sourceArray = this.options;
        values.forEach((value, index) => {
          if (sourceArray && sourceArray.length) {
            let selectObject = sourceArray.find(
              (item) => item[this.propsValueKey] === value
            );
            if (selectObject) {
              // 重新赋值查询的数组
              sourceArray = selectObject[this.propsChildrenKey];
              // 删除传出的对象的children，这样数据不那么冗杂
              let tempSelect = Object.assign({}, selectObject);
              delete tempSelect[this.propsChildrenKey];
              selectList.push(tempSelect);
            }
          }
        });
      }
      return selectList;
    },
  },
};
</script>

<style scoped lang="less">
.v-cascader-wrapper {
  display: inline-block;
  label {
    font-size: 14px;
    columns: #999;
    margin-right: 10px;
  }
}
</style>
