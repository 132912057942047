<template>
  <div class="amap-page-container">
    <el-dialog
      title="地图标注"
      width="80%"
      :close-on-click-modal="false"
      :visible.sync="showMapDialog"
      :before-close="hideMapDialog"
      @open="onOpenDialog"
    >
      <div class="toolbar">
        经度: {{ lng }}, 纬度: {{ lat }}, 地址: {{ address }}
      </div>
      <el-amap-search-box
        class="search-box-wrapper"
        :search-option="searchOption"
        :on-search-result="onSearchResult"
      ></el-amap-search-box>
      <el-amap
        class="remaim-vue-map"
        :vid="amapVid"
        :center="center"
        :zoom="zoom"
        :plugin="plugin"
        :events="events"
      >
        <el-amap-marker :position="center"></el-amap-marker>
      </el-amap>
      <span slot="footer" class="dialog-footer">
        <v-button
          @click="hideMapDialog"
          type
          text="取 消"
          class="margin-right-20"
        ></v-button>
        <v-button
          type="primary"
          @click="onSaveLngAndLat"
          text="确 定"
        ></v-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import VueAMap from "vue-amap";
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: "78e2bfb982b0712db9793642e51454ce",
  plugin: [
    "AMap.Scale",
    "AMap.OverView",
    "AMap.ToolBar",
    "AMap.MapType",
    "Geocoder",
    "AMap.Geolocation",
    "AMap.PlaceSearch",
  ],
  v: "1.4.4",
});
// @group 基础组件
// @title Map 地图组件
export default {
  name: "v-map",
  props: {
    // 经度
    selectedLng: {
      type: Number,
    },
    // 纬度
    selectedLat: {
      type: Number,
    },
    // 控制地图弹框的显示隐藏
    showMapDialog: {
      type: Boolean,
      default: false,
    },
    // 地图缩放参数
    zoom: {
      type: Number,
      default: 16,
    },
  },
  data() {
    let self = this;
    return {
      positionLng: 0,
      positionLat: 0,
      amapVid: "amap_vid_" + new Date().getTime(),
      searchOption: {
        city: "中国",
        citylimit: false,
        extensions: "all",
      },
      center: [self.selectedLng, self.selectedLat],
      events: {
        click(e) {
          let { lng, lat } = e.lnglat;
          self.lng = lng;
          self.lat = lat;
          self.getAddress(lng, lat);
          self.center = [lng, lat];
        },
      },
      plugin: [
        {
          pName: "Geolocation",
          events: {
            init(o) {
              // o 是高德地图定位插件实例
              o.getCurrentPosition((status, result) => {
                self.positionLng = result.position.lng;
                self.positionLat = result.position.lat;
                if (self.selectedLng && self.selectedLat) {
                  return false;
                } else {
                  if (result && result.position) {
                    self.lng = result.position.lng;
                    self.lat = result.position.lat;
                    self.center = [self.lng, self.lat];
                    self.getAddress(self.lng, self.lat);
                  }
                }
              });
            },
          },
        },
      ],
      // 位置信息
      lng: 0,
      lat: 0,
      address: "",
      postcode: "",
      areaCode: "",
    };
  },
  methods: {
    onOpenDialog() {
      if (this.selectedLng && this.selectedLat) {
        this.getAddress(this.selectedLng, this.selectedLat);
        this.lng = this.selectedLng;
        this.lat = this.selectedLat;
        this.center = [this.selectedLng, this.selectedLat];
      } else {
        this.getAddress(this.positionLng, this.positionLat);
        this.lng = this.positionLng;
        this.lat = this.positionLat;
        this.center = [this.positionLng, this.positionLat];
      }
    },
    getAddress(lng, lat) {
      // 这里通过高德 SDK 完成。
      /* eslint-disable no-undef */
      let geocoder = new AMap.Geocoder({
        radius: 1000,
        extensions: "all",
      });
      let _this = this;
      geocoder.getAddress([lng, lat], (status, result) => {
        if (status === "complete" && result.info === "OK") {
          if (result && result.regeocode) {
            _this.address = result.regeocode.formattedAddress;
            if (result.regeocode && result.regeocode.addressComponent) {
              // 获取高德的区域code
              _this.areaCode = result.regeocode.addressComponent.adcode;
            }
            _this.placeSearch(_this.address);
          } else {
            _this.address = "";
          }
        } else {
          _this.address = "";
        }
      });
    },
    onSearchResult(pois) {
      if (pois.length > 0) {
        let result = pois[0];
        this.lng = result.lng;
        this.lat = result.lat;
        this.address = result.name;
        this.areaCode = result.adcode;
        this.postcode = result.postcode;
        this.center = [result.lng, result.lat];
      }
    },
    onSaveLngAndLat() {
      let locationInfo = {
        lng: this.lng,
        lat: this.lat,
        postcode: this.postcode,
        address: this.address,
        areaCode: this.areaCode,
      };
      this.$emit("setLngAndLat", locationInfo);
      this.$emit("update:showMapDialog", false);
    },
    hideMapDialog() {
      this.$emit("update:showMapDialog", false);
    },

    // POI搜索
    placeSearch(address) {
      if (!address || address.length === 0) {
        return;
      }
      // 这里是为了获取邮编
      let placeSearch = new AMap.PlaceSearch({
        extensions: "all",
      });
      let _this = this;
      placeSearch.search(address, (status, result) => {
        let postCode = "";
        if (status === "complete" && result.info === "OK") {
          const { pois } = result.poiList;
          if (pois && pois.length > 0) {
            postCode = pois[0].postcode;
          }
        }
        _this.postcode = postCode;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.amap-page-container {
  position: relative;
  .remaim-vue-map {
    height: 500px;
  }
  .search-box-wrapper {
    position: relative;
    top: 50px;
    left: 20px;
  }
  .toolbar {
    position: absolute;
    height: 45px;
    padding-left: 10px;
    line-height: 44px;
    color: red;
  }
  .margin-right-20 {
    margin-right: 20px;
  }
}
</style>
