<template>
  <div class="v-tooltip">
    <el-tooltip
      v-model="model"
      class="item"
      effect="dark"
      :content="content"
      :placement="placement"
      :manual="manual"
    >
      <slot></slot>
    </el-tooltip>
  </div>
</template>

<script>
import Vue from "vue";
import { Tooltip } from "element-ui";
Vue.use(Tooltip);
export default {
  watch: {
    model(newValue) {
      this.$emit("input", newValue, this);
    },
  },
  created() {},
  components: {},
  props: {
    content: {
      type: String,
      default: "",
    },
    value: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      default: "",
    },
    manual: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      model: this.value,
    };
  },
  methods: {},
};
</script>
<style lang="less"></style>
