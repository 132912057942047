const routes = [
  {
    path: "rubbishSort/promiseLetter",
    name: "promiseLetter",
    meta: {
      title: "承诺书",
    },
    component: (resolve) =>
      require(["@/views/rubbishSort/promiseLetter.vue"], resolve),
  },
  {
    path: "rubbishSort/activity",
    name: "rubbishActivity",
    meta: {
      title: "垃圾分类活动",
    },
    component: (resolve) =>
      require(["@/views/rubbishSort/activity/index.vue"], resolve),
  },
  {
    path: "rubbishSort/editActivity",
    name: "rubEditActivity",
    meta: {
      echo: "rubbishSort/activity",
      title: "编辑垃圾分类活动",
    },
    component: (resolve) =>
      require(["@/views/rubbishSort/activity/editActivity.vue"], resolve),
  },
  {
    path: "rubbishSort/notPartyPeople",
    name: "notPartyPeople",
    meta: {
      echo: "rubbishSort/activity",
      title: "未参与垃圾分类活动",
    },
    component: (resolve) =>
      require(["@/views/rubbishSort/activity/notPartyPeople.vue"], resolve),
  },
  {
    path: "rubbishSort/question",
    name: "rubbishQuestion",
    meta: {
      title: "垃圾分类答题库",
    },
    component: (resolve) =>
      require(["@/views/rubbishSort/question/index.vue"], resolve),
  },
  {
    path: "rubbishSort/editQuestion",
    name: "editRubQuestion",
    meta: {
      echo: "rubbishSort/question",
      title: "编辑垃圾分类答题库",
    },
    component: (resolve) =>
      require(["@/views/rubbishSort/question/editQuestion.vue"], resolve),
  },
  {
    path: "rubbishSort/qualificationRateAudit",
    name: "qualificationRateAudit",
    meta: {
      echo: "rubbishSort/qualificationRateAudit",
      title: "合格率审核",
    },
    component: (resolve) =>
      require(["@/views/rubbishSort/qualification/qualificationList"], resolve),
  },
];

export default routes;
