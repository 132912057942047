<template>
  <div class="v-control v-button" :style="{ width: width + 'px' }">
    <el-button
      :disabled="disabled"
      :loading="loading"
      :style="oStyle"
      :size="size"
      :type="type"
      :round="round"
      :icon="icon"
      :plain="plain"
      :circle="circle"
      @click="click"
    >
      <slot v-if="text">{{ text }}</slot
      ><slot v-else> </slot>
    </el-button>
  </div>
</template>

<script>
import Vue from "vue";
import { Button } from "element-ui";
Vue.use(Button);
export default {
  watch: {
    value(newValue, oldValue) {
      this.changeModel(newValue);
    },
    model(newValue) {
      this.$emit("input", newValue, this);
    },
  },
  created() {},
  components: {},
  props: {
    loading: Boolean,
    oStyle: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "primary",
    },
    value: {
      type: String,
      default: "",
    },
    //尺寸 medium / small / mini
    size: String,
    // 按钮名称
    text: String,
    // 按钮宽度
    width: Number,
    // 图标类名
    icon: String,
    // 是否朴素按钮
    plain: Boolean,
    // 是否圆角按钮
    round: Boolean,
    // 是否圆形按钮
    circle: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputWidth: this.width,
    };
  },
  methods: {
    click() {
      this.$emit("click");
    },
    changeModel(newValue) {
      this.model = newValue;
      // 输入框的值改变时触发
      this.$emit("change", newValue);
    },
    change(value) {
      this.$emit("change", value);
    },
  },
};
</script>
<style lang="less">
.v-button {
  display: inline-block;
  .el-button {
    width: 100%;
    // height: 100%;
    // padding: 8px;
  }
  // .is-circle {
  //   min-width: 0;
  // }
  // .el-button--text {
  //   min-width: 0;
  //   padding: 0;
  // }
}
</style>
