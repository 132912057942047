<template>
  <div class="v-ueditor" v-loading="loading">
    <quill-editor
      ref="myQuillEditor"
      v-model="content"
      class="myQuillEditor ql-editor"
      :options="editorOption"
      @change="onChange($event)"
      @focus="quillEditorFocus($event)"
    />
    <form
      action
      method="post"
      enctype="multipart/form-data"
      id="uploadFormMulti"
    >
      <input
        style="display: none"
        :id="uniqueId"
        type="file"
        name="file"
        multiple
        accept="image/jpg, image/jpeg, image/png, image/gif"
        @change="uploadImg('uploadFormMulti')"
      />
    </form>
    <v-dialog
      title="视频上传"
      v-model="videoFlag"
      :close-on-click-modal="false"
      class="editerV"
      @confirm="confirm"
      :appendToBody="true"
    >
      <div class="editerVideo">
        <div class="editerVideo_title">
          <div
            :class="[
              'editerVideo_title_item',
              videoForm.tabUrl ? 'editerVideo_title_act' : '',
            ]"
            @click="onVideoTab(1)"
          >
            添加链接
          </div>

          <div
            :class="[
              'editerVideo_title_item',
              !videoForm.tabUrl ? 'editerVideo_title_act' : '',
            ]"
            @click="onVideoTab(2)"
          >
            上传视频
          </div>
        </div>

        <div class="editerVideo_main">
          <div class="editerVideo_main_url" v-if="videoForm.tabUrl">
            <div>视频地址：</div>
            <v-input
              size="small"
              v-model="videoForm.videoUrl"
              style="width: 300px"
            ></v-input>
          </div>
          <div v-if="!videoForm.tabUrl">
            <v-videoUpload
              :limit="1"
              :videoUrls.sync="videoForm.showVideoPath"
            />
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { quillEditor, Quill } from "vue-quill-editor";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { mapState } from "vuex";
import Video from "./video";
Quill.register(Video, true);
export default {
  watch: {
    content(newValue) {
      this.$emit("input", newValue, this);
    },
    value(newValue) {
      this.content = newValue;
    },
  },
  created() {},
  components: {
    quillEditor,
  },
  props: {
    isShowImg: {
      type: Boolean,
      default: true,
    },
    // 按钮宽度
    width: Number,
    type: {
      type: String,
      default: "primary",
    },
    value: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    prefixUrl: {
      type: String,
      default: "",
    },
    //是否禁用
    isDisable: {
      type: Boolean,
      default: false,
    },
    // 页面存在多个富文本使用
    uniqueId: {
      type: String,
      default: "uniqueId",
    },
  },
  data() {
    let _this = this;
    return {
      // 相关变量
      indexVideo: 0,
      loading: false,
      videoFlag: false,
      videoForm: {
        showVideoPath: "",
        tabUrl: true,
        videoUrl: "",
      },
      uploadUrl: "/gateway/blade-resource/oss/endpoint/put-file",
      // uniqueId: "uniqueId",
      content: this.value, // 富文本编辑器默认内容
      editorOption: {},
    };
  },
  computed: {
    ...mapState("app", ["authorization", "userInfo"]),
  },
  mounted() {
    var _this = this;
    this.handlePaste();
    var imgHandler = async function (image) {
      if (image) {
        var fileInput = document.getElementById(_this.uniqueId); //隐藏的file文本ID
        fileInput.click(); //加一个触发事件
      }
    };
    this.$refs.myQuillEditor.quill
      .getModule("toolbar")
      .addHandler("image", imgHandler);
    this.$refs.myQuillEditor.quill
      .getModule("toolbar")
      .addHandler("video", this.onVideo);
  },
  methods: {
    handlePaste() {
      let _this = this;
      let quill = this.$refs.myQuillEditor.quill;
      quill.root.addEventListener(
        "paste",
        (evt) => {
          if (
            evt.clipboardData &&
            evt.clipboardData.files &&
            evt.clipboardData.files.length
          ) {
            evt.preventDefault();
            [].forEach.call(evt.clipboardData.files, (file) => {
              if (!file.type.match(/^image\/(gif|jpe?g|a?png|bmp)/i)) {
                return;
              }
              const formData = new FormData();
              formData.append("file", file);
              _this.handleUploadImg(formData);
            });
          }
        },
        false
      );
    },
    // 上传图片链接
    confirm() {
      let videoUrl = "";
      if (this.videoForm.tabUrl) {
        videoUrl = this.videoForm.videoUrl;
      } else {
        videoUrl = this.videoForm.showVideoPath;
      }
      if (videoUrl) {
        let quill = this.$refs.myQuillEditor.quill;
        quill.insertEmbed(this.indexVideo, "video", videoUrl);
        quill.setSelection(this.indexVideo + 1);
        this.videoFlag = false;
      } else {
        this.$message({
          message: "请填写视频链接",
          type: "warning",
        });
      }
    },
    onVideo() {
      this.videoFlag = true;
      this.videoForm = {
        showVideoPath: "",
        tabUrl: true,
        videoUrl: "",
      };
      //当编辑器中没有输入文本时，这里获取到的 range 为 null   获取光标位置
      var range = this.$refs.myQuillEditor.quill.getSelection();
      if (range == null) {
        this.indexVideo = 0;
      } else {
        this.indexVideo = range.index;
      }
    },
    onVideoTab(val) {
      // 链接与视频上传 tab切换
      if (val == 1) {
        this.videoForm.showVideoPath = "";
        this.videoForm.tabUrl = true;
      } else {
        this.videoForm.videoUrl = "";
        this.videoForm.tabUrl = false;
      }
    },
    async handleUploadImg(data, fallback) {
      let _this = this;
      try {
        //调用上传文件接口
        _this.loading = true;
        let res = await this.$axios.post(`${this.uploadUrl}`, data, {
          headers: {
            Authorization: this.authorization,
            "hc-auth": `${this.userInfo.tokenType} ${this.userInfo.accessToken}`,
          },
        });
        //返回上传文件的地址
        let url = res.data.link;
        if (url != undefined && url != "") {
          let Range = _this.$refs.myQuillEditor.quill.getSelection();
          url = url.indexOf("http") != -1 ? url : _this.prefixUrl + url;
          //上传文件成功之后在富文本中回显(显示)
          _this.$refs.myQuillEditor.quill.insertEmbed(
            Range != null ? Range.index : 0,
            "image",
            url
          );
        } else {
          _this.$message.warning("图片上传失败");
        }
        fallback ? fallback() : "";
        _this.loading = false;
      } catch ({ message: msg }) {
        fallback ? fallback() : "";
        _this.$message.warning(msg);
        _this.loading = false;
      }
    },
    uploadImg: async function () {
      var _this = this;
      //构造formData对象
      var formData = new FormData();
      formData.append("file", document.getElementById(_this.uniqueId).files[0]);
      this.handleUploadImg(formData, () => {
        //成功之后,将文件的文本框的value置空
        document.getElementById(_this.uniqueId).value = "";
      });
    },
    // 对富文本进行不可编辑操作
    quillEditorFocus(event) {
      if (this.isDisable) {
        event.enable(false);
      }
    },
    onChange(event) {
      if (this.isDisable) {
        event.quill.enable(false);
      } else {
        this.$emit("input", this.content);
      }
    },
  },
};
</script>
<style lang="less">
.v-ueditor {
  height: 400px;
  line-height: normal;
  padding-right: 20px;
  .myQuillEditor {
    display: flex;
    height: 100%;
    flex-direction: column;
  }
  .ql-container {
    flex: 1;
    overflow: auto;
  }
  // 视频上传组件
  .editerV /deep/ .el-dialog__body {
    padding: 0px 20px 20px 20px;
  }
}
</style>
<style lang="less">
.editerVideo {
  width: 100%;
  .editerVideo_title {
    display: flex;
    height: 30px;
    line-height: 30px;
    width: 100%;
    color: #1f2f3d;
    font-weight: bold;
    justify-content: flex-start;
    border-bottom: 2px solid #dcdfe6;
    margin-bottom: 20px;
  }
  .editerVideo_title_act {
    color: #409eff;
    border-bottom: 2px solid #409eff;
    margin-bottom: -2px;
  }
  .editerVideo_title_item {
    margin-right: 10px;
  }
  .editerVideo_main {
    width: 100%;
    height: 120px;
  }
  .editerVideo_main_url {
    width: 100%;
    height: 200px;
    line-height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 35px;
  }
  .avatar-uploader_video {
    width: 100px;
    height: 100px;
    border: 1px solid #d9d9d9;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }
  .avatar-uploader-videoiocn {
    font-size: 28px;
    color: #d9d9d9;
    line-height: 90px;
    text-align: center;
  }
  .videoProgress {
    margin-top: 30px;
  }
}
</style>
