import Vue from "vue";
import VueRouter from "vue-router";
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);
let viewsRoutes = [];
const routerContext = require.context("./module", false, /\.js$/);
routerContext.keys().forEach((route) => {
  const routerModule = routerContext(route);
  viewsRoutes = [...viewsRoutes, ...(routerModule.default || routerModule)];
});

/* 子路由创建规则
  1. 存在 meta.title 的路由才会显示对应的面包屑
  2. 子路由尽可能不要使用 / 开头
    如果使用 / 开头，vuex app.js 中的menuList对象path字段确保与地址栏显示设置统一
  3. 对于不能在侧边栏显示的路由
    （非权限控制的不能显示，而是永远不会在侧边栏显示，例如：新增页，编辑页。。。），
    meta内需要添加 echo 字段，用于指定侧边栏和面包屑的回显
*/

/* 用于判断路由是否存在名称重复 | 或路径重复 */
function vaildRouteRepeat(routes, str) {
  console.log("触发了");
  let obj = {};
  routes.forEach((route) => {
    if (obj[route[str]]) {
      obj[route[str]]++;
      console.log(route[str], obj[route[str]]);
    } else {
      obj[route[str]] = 1;
    }
  });
  let result = [];
  Object.keys(obj).forEach((key) => {
    if (obj[key] > 1) {
      console.log(key);
      result.push(key);
    }
  });
  if (result.length > 0) {
    console.log(result, "result---");
    alert(`检测到重复的${str}：${result.join("、")}`);
  }
}
vaildRouteRepeat(viewsRoutes, "name"); // name重复
vaildRouteRepeat(viewsRoutes, "path"); // path重复
/* end */

const routes = [
  {
    path: "/thirdBlank",
    name: "thirdBlank",
    component: () => import("../views/other/thirdBlank.vue"),
  },
  {
    path: "/",
    redirect: { path: "/login" },
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("../views/home/index.vue"),
    children: [...viewsRoutes],
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: "象山县未来社区智慧服务平台",
    },
    component: () => import("../views/login/index.vue"),
  },
  {
    path: "/jumpLogin",
    name: "jumpLogin",
    component: () => import("../views/login/JumpLogin.vue"),
  },
  {
    path: "/guide",
    name: "guide",
    meta: {
      title: "象山县未来社区智慧服务平台",
    },
    component: () => import("../views/guide/index.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});
export default router;
