function getHashParam(name) {
  try {
    if (!name) {
      return "";
    }
    if (location.hash.indexOf("?") === -1) {
      return "";
    }
    let params = location.hash.split("?")[1];
    let paramList = [];
    var param = null;
    let theRequest = {};
    if (params.length > 0) {
      if (params.indexOf("&") >= 0) {
        paramList = params.split("&");
      } else {
        paramList[0] = params;
      }
      for (var i = 0; i < paramList.length; i++) {
        theRequest[paramList[i].split("=")[0]] = paramList[i].split("=")[1];
      }
      param = theRequest[name];
    }
    return param;
  } catch (error) {
    return "";
  }
}
function getOption(option, arr, attr) {
  for (var index in arr) {
    if (arr[index][attr] == option) {
      return arr[index];
    }
  }
  return {};
}

/**
 *
 * 生成v-input组件的VNode
 * @export
 * @param {Object} $vue vue实例
 * @param {Object} row  当前行数据
 * @param {String} prop 当前列的prop
 * @returns 返回生成的VNode对象
 */
function createInputVNode($vue, row, prop, opts = {}) {
  let VNode = $vue.$createElement("v-input", {
    props: {
      ...(opts.attrs || {}),
      value: row[prop],
    },
    on: {
      input(newValue) {
        row[prop] = newValue;
      },
      ...(opts.methods || {}),
    },
  });
  return VNode;
}

/**
 *
 * 生成包含 v-select v-input 组件的VNode, 专为 自定义积分规则 - (积分有效期 / 上限) 生成的 vNode
 * @export
 * @param {Object} $vue vue实例
 * @param {Object} row  当前行数据
 * @param {String} prop 当前列的prop
 * @returns 返回生成的VNode对象
 */
function createDivAndPVNode($vue, row, prop, opts) {
  let childList = row[prop].split("\r\n");
  const childVNode = childList.map((v, index) =>
    createPVNode($vue, childList, index, opts && opts.childOpts)
  );
  let VNode = $vue.$createElement("div", null, childVNode);
  return VNode;
}

function createPVNode($vue, row, prop, opts) {
  let VNode = $vue.$createElement("p", {
    domProps: {
      innerHTML: row[prop],
    },
    style: {
      maxWidth: "400px",
      whiteSpace: "norwap",
      ...(opts ? opts.style : {}),
    },
    props: {
      options: opts && opts.options,
    },
    on: {
      input(newValue) {
        row[prop] = newValue;
      },
      change($event) {
        if (opts.cb) {
          opts.cb($event);
        }
      },
    },
  });
  return VNode;
}

function createDivAndImgVNode($vue, row, prop, opts) {
  if (!row[prop]) {
    return "";
  }
  let childList = row[prop].split("\r\n");
  const childVNode = childList.map((v, index) =>
    createDivVNode($vue, childList, index, opts && opts.childOpts)
  );
  let VNode = $vue.$createElement("div", null, childVNode);
  return VNode;
}

function createDivVNode($vue, row, prop, childOpts) {
  let childList = row[prop].split(",");
  const childVNode = childList.map((v, index) =>
    createImgVNode($vue, childList, index, childOpts && childOpts.childOpts)
  );
  let VNode = $vue.$createElement("div", null, childVNode);
  return VNode;
}

/**
 *
 * 生成包含 v-select v-input 组件的VNode, 专为 自定义积分规则 - (积分有效期 / 上限) 生成的 vNode
 * @export
 * @param {Object} $vue vue实例
 * @param {Object} row  当前行数据
 * @param {String} prop 当前列的prop
 * @returns 返回生成的VNode对象
 */
function createInputSpanVNode($vue, row, prop, opts) {
  let VNode = $vue.$createElement("div", null, [
    $vue.$createElement("v-select", {
      props: {
        options: opts.options,
        value: row[prop],
        width: 120,
      },
      on: {
        input(newValue) {
          row[prop] = newValue;
        },
        change($event) {
          if (opts.cb) {
            opts.cb($event);
          }
        },
      },
    }),
    $vue.$createElement("span", {
      domProps: {
        innerText: opts.spanInnerTextOne,
      },
      style: {
        display: opts.spanDisplayOne,
      },
    }),
    $vue.$createElement("v-input", {
      props: {
        value: row[opts.inputNumberValue],
        width: opts.inputNumberWidth,
        ...(opts.attrs || {}),
      },
      style: {
        display: opts.inputDisplay,
      },
      on: {
        input(newValue) {
          row[opts.inputNumberValue] = newValue;
        },
        ...(opts.methods || {}),
      },
    }),
    $vue.$createElement("span", {
      domProps: {
        innerText: opts.spanInnerTextTwo,
      },
      style: {
        display: opts.spanDisplayTwo,
      },
    }),
  ]);
  return VNode;
}

/**
 *
 * 生成包含 v-select v-input 组件的VNode, 专为 自定义积分规则 - 规则 生成的 vNode
 * @export
 * @param {Object} $vue vue实例
 * @param {Object} row  当前行数据
 * @param {String} prop 当前列的prop
 * @returns 返回生成的VNode对象
 */
function createRulesVNode($vue, row, prop, opts) {
  let VNode = $vue.$createElement("div", null, [
    $vue.$createElement("span", {
      domProps: {
        innerText: opts.spanInnerTextOne,
      },
      style: {
        display: opts.spanDisplayOne,
      },
    }),
    $vue.$createElement("v-input", {
      props: {
        value: JSON.parse(row[prop]).value,
        width: opts.inputNumberWidth,
        ...(opts.attrs || {}),
      },
      style: {
        display: opts.inputDisplay,
      },
      on: {
        ...(opts.methods || {}),
      },
    }),
    $vue.$createElement("v-select", {
      props: {
        options: opts.options,
        value: JSON.parse(row[prop]).type,
        width: opts.selectWidth,
      },
      on: {
        input(newValue) {
          const newJson = JSON.parse(row[prop]);
          newJson.type = newValue;
          row[prop] = JSON.stringify(newJson);
        },
        change($event) {
          if (opts.cb) {
            opts.cb($event);
          }
        },
      },
    }),
    $vue.$createElement("span", {
      domProps: {
        innerText: opts.spanInnerTextTwo,
      },
      style: {
        display: opts.spanDisplayTwo,
      },
    }),
  ]);
  return VNode;
}

/**
 *
 * 生成v-input-number组件的VNode
 * @export
 * @param {Object} $vue vue实例
 * @param {Object} row  当前行数据
 * @param {String} prop 当前列的prop
 * @returns 返回生成的VNode对象
 */
function createNumberVNode($vue, row, prop, opts = {}) {
  let VNode = $vue.$createElement("v-input-number", {
    props: {
      ...(opts.attrs || {}),
      value: row[prop],
    },
    on: {
      input(newValue) {
        row[prop] = newValue;
      },
      ...(opts.methods || {}),
    },
  });
  return VNode;
}

/**
 *
 * 生成v-select组件的VNode
 * @export
 * @param {Object} $vue vue实例
 * @param {Object} row  当前行数据
 * @param {String} prop 当前列的prop
 * @returns 返回生成的VNode对象
 */
function createSelectVNode($vue, row, prop, opts = {}) {
  let VNode = $vue.$createElement("v-select", {
    props: {
      ...(opts.attrs || {}),
      options: opts.options,
      value: row[prop],
    },
    on: {
      input(newValue) {
        row[prop] = newValue;
      },
      // 新增select change 事件的监听
      change($event) {
        if (opts.cb) {
          opts.cb($event);
        }
      },
      ...(opts.methods || {}),
    },
  });
  return VNode;
}

/**
 *
 * 生成原生img的VNode
 * @export
 * @param {Object} $vue vue实例
 * @param {Object} row  当前行数据
 * @param {String} prop 当前列的prop
 * @returns 返回生成的VNode对象
 */
function createImgVNode($vue, row, prop, opts) {
  if (!opts) {
    opts = {};
  }

  if (!opts.imgUrl && !row[prop]) {
    return null;
  }
  let VNode = $vue.$createElement("img", {
    attrs: {
      src: opts.imgUrl || row[prop],
    },
    style: {
      width: opts.width || "48px",
      height: opts.height || "48px",
      objectFit: "cover",
      ...opts.style,
    },
    on: {
      click: () => {
        if (opts.cb) {
          opts.cb(row, row[prop]);
        } else {
          let imgUrl = opts.imgUrl || row[prop];
          if (Array.isArray(imgUrl)) {
            $vue.$previewBox(imgUrl);
          } else {
            if (imgUrl) {
              $vue.$previewBox([imgUrl]);
            }
          }
        }
      },
    },
  });
  return VNode;
}

/**
 *
 * 生成v-tooltip组件的VNode
 * @export
 * @param {Object} $vue vue实例
 * @param {Object} row  当前行数据
 * @param {String} prop 当前列的prop
 * @returns 返回生成的VNode对象
 */
function createTooltipVNode($vue, row, prop, opts) {
  let optProps = {};
  for (let key in opts) {
    optProps[key] = opts[key];
  }
  let VNode = $vue.$createElement("v-tooltip", {
    props: optProps,
  });
  return VNode;
}

/**
 *
 * 生成v-switch组件的VNode
 * @export
 * @param {Object} $vue vue实例
 * @param {Object} row  当前行数据
 * @param {String} prop 当前列的prop
 * @returns 返回生成的VNode对象
 */
function createSwitchVNode($vue, row, prop, opts) {
  let optProps = {};
  for (let key in opts) {
    optProps[key] = opts[key];
  }
  let VNode = $vue.$createElement("v-switch", {
    props: optProps,
    on: {
      change: ($event) => {
        opts.cb($event);
      },
    },
  });
  return VNode;
}

/**
 *
 * 生成html片段的VNode
 * @export
 * @param {Object} $vue vue实例
 * @param {String} innerHTML html片段
 * @returns 返回生成的VNode对象
 */
function createHTMLVNode($vue, innerHTML, row, prop, opts) {
  if (!opts) {
    opts = {};
  }
  let VNode = $vue.$createElement("span", {
    domProps: {
      innerHTML: innerHTML,
    },
    style: {
      color: opts.color || "#1B8CF2",
      cursor: "pointer",
      ...(opts.style || {}),
    },
    on: {
      click: (e) => {
        opts.cb && opts.cb(e, row, row[prop]);
      },
    },
  });
  return VNode;
}

/**
 *
 * 生成alink样式的VNode
 * @export
 * @param {Object} $vue vue实例
 * @param {Object} row 当前行数据
 * @param {Object} opts 配置项(目前主要包括颜色及回调配置)
 * @returns 返回生成的VNode对象
 */
function createAlinkVNode($vue, row, prop, opts) {
  if (!opts) {
    opts = {};
  }
  // 调用方传值则用调用方opts.text否则取默认的row[prop]
  // 最终处理undefined及空字符串直接返回不带标签的空字符串 （性能比VNode好）
  let innerText = opts.text !== undefined ? opts.text : row[prop];
  if (innerText === "" || innerText === undefined) {
    return "";
  }
  let VNode = $vue.$createElement("span", {
    domProps: {
      innerText,
    },
    style: {
      color: opts.color || "#1B8CF2",
      cursor: "pointer",
    },
    on: {
      click: () => {
        opts.cb && opts.cb(row, row[prop]);
      },
    },
  });
  return VNode;
}
function validUsername(str) {
  const valid_map = ["admin", "editor"];
  return valid_map.indexOf(str.trim()) >= 0;
}
const _localStorage = {
  setItem(key, value) {
    window.localStorage.setItem(key, value);
  },
  getItem(key) {
    let value = window.localStorage.getItem(key);
    return value;
  },
  removeItem(key) {
    window.localStorage.removeItem(key);
  },
};
function newArr(arr) {
  return Array.from(new Set(arr));
}
function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result;

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp;

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };

  return function (...args) {
    context = this;
    timestamp = +new Date();
    const callNow = immediate && !timeout;
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }

    return result;
  };
}
export {
  debounce,
  getHashParam,
  getOption,
  createImgVNode,
  createAlinkVNode,
  createDivAndPVNode,
  createDivAndImgVNode,
  validUsername,
  createHTMLVNode,
  _localStorage,
  newArr,
};
