import { mapHelper } from "@/utils/common";

const relationOptions = [
  {
    value: 0,
    label: "业主",
  },
  {
    value: 1,
    label: "家属",
  },
  {
    value: 2,
    label: "租客",
  },
  {
    value: 3,
    label: "访客",
  },
  {
    value: 4,
    label: "上门服务人员",
  },
];

const { map: relationOptionsMap, setOps: relationOptionsOps } =
  mapHelper.setMap(relationOptions);

const userTypeOptions = [
  {
    value: "1",
    label: "居民",
  },
  {
    value: "2",
    label: "企业/商户",
  },
  {
    value: "3",
    label: "服务机构",
  },
];
const { map: userTypeOptionsMap, setOps: userTypeOptionsOps } =
  mapHelper.setMap(userTypeOptions);

export {
  relationOptions,
  relationOptionsMap,
  userTypeOptionsMap,
  userTypeOptions,
};
