const routes = [
  //菜单管理
  {
    path: "setSystem/menuManage",
    name: "menuManage",
    meta: {
      title: "菜单管理",
    },
    component: (resolve) =>
      require(["@/views/setSystem/menuManage/index.vue"], resolve),
  },
  //新增菜单
  {
    path: "setSystem/addMenu",
    name: "addMenu",
    meta: {
      title: "新增子菜单",
      echo: "setSystem/menuManage",
    },
    component: (resolve) =>
      require(["@/views/setSystem/menuManage/addMenu.vue"], resolve),
  },
  {
    path: "setSystem/advice",
    name: "advice-feedBack",
    meta: {
      title: "意见反馈",
    },
    component: (resolve) =>
      require(["@/views/setSystem/advice/index.vue"], resolve),
  },
  {
    path: "setSystem/accountList",
    name: "accountList",
    meta: {
      title: "账号列表",
    },
    component: (resolve) =>
      require(["@/views/setSystem/accountManager/accountList.vue"], resolve),
  },
  {
    path: "setSystem/accountAdd",
    name: "accountAdd",
    meta: {
      echo: "setSystem/accountList",
      title: "新增账号",
    },
    component: (resolve) =>
      require(["@/views/setSystem/accountManager/accountAdd.vue"], resolve),
  },
  {
    path: "setSystem/roleList",
    name: "roleList",
    meta: {
      title: "角色管理",
    },
    component: (resolve) =>
      require(["@/views/setSystem/roleManager/roleList.vue"], resolve),
  },
  {
    path: "setSystem/roomHandle",
    name: "roomHandle",
    meta: {
      title: "房号审核",
    },
    component: (resolve) =>
      require(["@/views/setSystem/roomHandle/index.vue"], resolve),
  },
  {
    path: "setSystem/roomManager",
    name: "roomManager",
    meta: {
      title: "房号管理",
    },
    component: (resolve) =>
      require(["@/views/setSystem/roomHandle/roomManager.vue"], resolve),
  },
  // 实名认证
  {
    path: "setSystem/realNameAuditList",
    name: "realNameAuditList",
    meta: {
      title: "实名认证",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/realNameAudit/realNameAuditList.vue",
      ], resolve),
  },
  {
    path: "setSystem/realNameAuditForm",
    name: "realNameAuditForm",
    meta: {
      echo: "setSystem/realNameAuditList",
      title: "实名认证审核",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/realNameAudit/realNameAuditForm.vue",
      ], resolve),
  },
  // 用户标签列表
  {
    path: "setSystem/userLabelList",
    name: "userLabelList",
    meta: {
      title: "用户标签管理列表",
    },
    component: (resolve) =>
      require(["@/views/setSystem/userLabel/userLabelList.vue"], resolve),
  },
  // 用户标签表单
  {
    path: "setSystem/userLabelForm",
    name: "userLabelForm",
    meta: {
      title: "用户标签表单",
      echo: "setSystem/userLabelList",
    },
    component: (resolve) =>
      require(["@/views/setSystem/userLabel/userLabelForm.vue"], resolve),
  },
  // 用户人数列表
  {
    path: "setSystem/userNumberList",
    name: "userNumberList",
    meta: {
      title: "用户人数列表",
      echo: "setSystem/userLabelList",
    },
    component: (resolve) =>
      require(["@/views/setSystem/userLabel/userNumberList.vue"], resolve),
  },

  // 商户标签列表
  {
    path: "setSystem/businessLabelList",
    name: "businessLabelList",
    meta: {
      title: "商户标签管理列表",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/businessLabel/businessLabelList.vue",
      ], resolve),
  },
  // 商户标签标签表单
  {
    path: "setSystem/businessLabelForm",
    name: "businessLabelForm",
    meta: {
      title: "商户标签表单",
      echo: "setSystem/businessLabelList",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/businessLabel/businessLabelForm.vue",
      ], resolve),
  },
  // 商户人数列表
  {
    path: "setSystem/businessNumberList",
    name: "businessNumberList",
    meta: {
      title: "商户人数列表",
      echo: "setSystem/businessLabelList",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/businessLabel/businessNumberList.vue",
      ], resolve),
  },

  // 用户轨迹列表
  {
    path: "setSystem/userTrackList",
    name: "userTrackList",
    meta: {
      title: "用户轨迹列表",
    },
    component: (resolve) =>
      require(["@/views/setSystem/userTrack/userTrackList.vue"], resolve),
  },

  // 用户管理
  {
    path: "setSystem/userManageList",
    name: "userManageList",
    meta: {
      title: "用户列表",
    },
    component: (resolve) =>
      require(["@/views/setSystem/userManage/userManageList.vue"], resolve),
  },
  {
    path: "setSystem/userManageForm",
    name: "userManageForm",
    meta: {
      echo: "setSystem/userManageList",
      title: "用户审核",
    },
    component: (resolve) =>
      require(["@/views/setSystem/userManage/userManageForm.vue"], resolve),
  },
  {
    path: "setSystem/platformManager",
    name: "platformManager",
    meta: {
      title: "平台应用管理",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/applicationManager/platformManager/index.vue",
      ], resolve),
  },
  {
    path: "setSystem/platformManagerForm",
    name: "platformManagerForm",
    meta: {
      title: "平台应用管理",
      echo: "setSystem/platformManager",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/applicationManager/platformManager/platformManagerForm.vue",
      ], resolve),
  },
  {
    path: "setSystem/tenantManager",
    name: "tenantManager",
    meta: {
      title: "租户应用管理",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/applicationManager/tenantManager/index.vue",
      ], resolve),
  },
  {
    path: "setSystem/tenantManagerForm",
    name: "tenantManagerForm",
    meta: {
      title: "租户应用管理",
      echo: "setSystem/tenantManager",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/applicationManager/tenantManager/tenantManagerForm.vue",
      ], resolve),
  },
  {
    path: "setSystem/carManager",
    name: "carManager",
    meta: {
      title: "车位管理",
    },
    component: (resolve) =>
      require(["@/views/setSystem/carManager/index.vue"], resolve),
  },
  {
    path: "setSystem/subscribeVerify",
    name: "subscribeVerify",
    meta: {
      title: "申请接口订阅",
    },
    component: (resolve) =>
      require(["@/views/setSystem/developVerify/subscribeVerify.vue"], resolve),
  },
  {
    path: "developer/account",
    name: "developer-account",
    meta: {
      title: "开发者账户管理",
    },
    component: (resolve) =>
      require(["@/views/setSystem/developVerify/account.vue"], resolve),
  },
  {
    path: "developer/accountForm",
    name: "accountForm",
    meta: {
      title: "新增社区账户",
      echo: "developer/account",
    },
    component: (resolve) =>
      require(["@/views/setSystem/developVerify/accountForm.vue"], resolve),
  },
  {
    path: "setSystem/subscribeList",
    name: "subscribeList",
    meta: {
      title: "接口订阅列表",
    },
    component: (resolve) =>
      require(["@/views/setSystem/developVerify/subscribeList.vue"], resolve),
  },
  {
    path: "setSystem/subscribeTimes",
    name: "subscribeTimes",
    meta: {
      title: "订阅次数审核",
    },
    component: (resolve) =>
      require(["@/views/setSystem/developVerify/subscribeTimes.vue"], resolve),
  },
  {
    path: "setSystem/addSubscribe",
    name: "addSubscribe",
    meta: {
      title: "新增接口",
      echo: "setSystem/subscribeList",
    },
    component: (resolve) =>
      require(["@/views/setSystem/developVerify/addSubscribe.vue"], resolve),
  },
  {
    path: "setSystem/applicationVerify",
    name: "applicationVerify",
    meta: {
      title: "开发者第三方应用管理",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/developVerify/applicationVerify.vue",
      ], resolve),
  },
  {
    path: "setSystem/applicationVerifyForm",
    name: "applicationVerifyForm",
    meta: {
      title: "开发者第三方应用管理",
      echo: "setSystem/applicationVerify",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/developVerify/applicationVerifyForm.vue",
      ], resolve),
  },
  // 常见问题模块
  {
    path: "setSystem/commonProblem",
    name: "commonProblem",
    meta: {
      title: "常见问题",
    },
    component: (resolve) =>
      require(["@/views/setSystem/commonProblem/index.vue"], resolve),
  },
  // 常见问题模块-回复
  {
    path: "setSystem/commonProblemReply",
    name: "commonProblemReply",
    meta: {
      title: "常见问题",
      echo: "setSystem/commonProblem",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/commonProblem/commonProblemReply.vue",
      ], resolve),
  },
  //登录日志
  {
    path: "setSystem/loginRecordsLog",
    name: "loginRecordsLog",
    meta: {
      title: "登录日志",
    },
    component: (resolve) =>
      require(["@/views/setSystem/loginRecordsLog/index.vue"], resolve),
  },
  // 安全日志管理
  {
    path: "setSystem/secureLog",
    name: "SecureLog",
    meta: {
      title: "安全日志管理",
    },
    component: (resolve) => require(["@/views/setSystem/secureLog/index.vue"], resolve),
  },
  //租户管理
  {
    path: "setSystem/tenantManagement",
    name: "tenantManagement",
    meta: {
      title: "租户管理",
    },
    component: (resolve) =>
      require(["@/views/setSystem/tenantManagement/index.vue"], resolve),
  },
  //新增租户管理
  {
    path: "setSystem/addTenantManagement",
    name: "addTenantManagement",
    meta: {
      title: "新增租户",
    },
    component: (resolve) =>
      require([
        "@/views/setSystem/tenantManagement/addTenantManagement.vue",
      ], resolve),
  },
];

export default routes;
