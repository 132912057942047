// 公共能力中心 - 模块 - 路由
const routes = [
  // 用户中心
  {
    path: 'pubCapCen/userCent',
    name: 'pubCapUserCen',
    meta: {
      title: '用户中心'
    },
    redirect: 'pubCapCen/userCent/residentUser'
  },
  // 用户中心 - 居民用户 - 列表
  {
    path: 'pubCapCen/userCent/residentUser',
    name: 'pubCapUserCenResidentUser',
    meta: {
      title: '居民用户'
    },
    component: resolve => require(['@/views/publicCapabilityCenter/userCenter/residentUser/residentUserList.vue'], resolve)
  },
  // 用户中心 - 居民用户 - 表单
  {
    path: 'pubCapCen/userCent/residentUserForm',
    name: 'pubCapUserCenResidentUserForm',
    meta: {
      echo: 'pubCapCen/userCent/residentUser',
      title: '居民用户'
    },
    component: resolve => require(['@/views/publicCapabilityCenter/userCenter/residentUser/residentUserForm.vue'], resolve)
  },
  // 用户中心 - 服务用户 - 列表
  {
    path: 'pubCapCen/userCent/serviceUser',
    name: 'pubCapUserCenServiceUser',
    meta: {
      title: '服务用户'
    },
    component: resolve => require(['@/views/publicCapabilityCenter/userCenter/serviceUser/serviceUserList.vue'], resolve)
  },
  // 用户中心 - 服务用户 - 表单
  {
    path: 'pubCapCen/userCent/serviceUserForm',
    name: 'pubCapUserCenServiceUserForm',
    meta: {
      echo: 'pubCapCen/userCent/serviceUser',
      title: '服务用户'
    },
    component: resolve => require(['@/views/publicCapabilityCenter/userCenter/serviceUser/serviceUserForm.vue'], resolve)
  },
  // 空间中心 - 列表
  {
    path: 'pubCapCen/spanCent/spaceCenter',
    name: 'pubCapSpacCenSpaceCenter',
    meta: {
      title: '空间中心'
    },
    component: resolve => require(['@/views/publicCapabilityCenter/spaceCenter/spaceCenterList.vue'], resolve)
  },
  // 空间中心 - 表单
  {
    path: 'pubCapCen/spanCent/spaceCenterForm',
    name: 'pubCapSpacCenSpaceCenterForm',
    meta: {
      echo: 'pubCapCen/spanCent/spaceCenter',
      title: '空间中心'
    },
    component: resolve => require(['@/views/publicCapabilityCenter/spaceCenter/spaceCenterForm.vue'], resolve)
  },
  // 文件服务中心 - 列表
  {
    path: 'pubCapCen/fileCent/fileServicesList',
    name: 'pubCapFileCenFileServicesList',
    meta: {
      title: '文件服务中心'
    },
    component: resolve => require(['@/views/publicCapabilityCenter/fileServicesCenter/fileServicesList.vue'], resolve)
  },
  // 日志中心 - 列表
  {
    path: 'pubCapCen/logCent/logList',
    name: 'pubCapLogCenLogList',
    meta: {
      title: '日志中心'
    },
    component: resolve => require(['@/views/publicCapabilityCenter/logCenter/logList.vue'], resolve)
  },
  // 积分中心 - 积分规则配置 - 列表
  {
    path: 'pubCapCen/inteCent/integralRuleConfig',
    name: 'pubCapInteCenIntegralRuleConfig',
    meta: {
      title: '积分规则配置'
    },
    // component: resolve => require(['@/views/publicCapabilityCenter/integralCenter/integralRuleConfig.vue'], resolve)
    component: resolve => require(['@/views/futureService/bonusPointSetting/bonusPointSettingList.vue'], resolve)
  },
  // 积分中心 - 积分查询 - 列表
  {
    path: 'pubCapCen/inteCent/integralList',
    name: 'pubCapInteCenIntegralList',
    meta: {
      title: '积分查询'
    },
    component: resolve => require(['@/views/publicCapabilityCenter/integralCenter/integralList.vue'], resolve)
  },
  // 工单中心 - 工单订单 - 列表
  {
    path: 'pubCapCen/wordOrderCent/workOrder',
    name: 'pubCapGdCenWorkOrder',
    meta: {
      title: '工单订单'
    },
    // component: resolve => require(['@/views/publicCapabilityCenter/workOrderCenter/.vue'], resolve)
    component: resolve => require(['@/views/futureService/workOrderManagement/workOrder.vue'], resolve)
  },
  // 工单中心 - 工单类型 - 列表
  {
    path: 'pubCapCen/wordOrderCent/workOrderType',
    name: 'pubCapGdCenWorkOrderType',
    meta: {
      title: '工单类型'
    },
    // component: resolve => require(['@/views/publicCapabilityCenter/workOrderCenter/.vue'], resolve)
    component: resolve => require(['@/views/futureService/workOrderManagement/workOrderType.vue'], resolve)
  },
  // 工单中心 - 人员配置 - 列表
  {
    path: 'pubCapCen/wordOrderCent/staffing',
    name: 'pubCapGdCenStaffing',
    meta: {
      title: '人员配置'
    },
    // component: resolve => require(['@/views/publicCapabilityCenter/workOrderCenter/.vue'], resolve)
    component: resolve => require(['@/views/futureService/workOrderManagement/staffing.vue'], resolve)
  },
  // 工单中心 - 转派记录 - 列表
  {
    path: 'pubCapCen/wordOrderCent/transferRecord',
    name: 'pubCapGdCentTransferRecord',
    meta: {
      title: '转派记录'
    },
    // component: resolve => require(['@/views/publicCapabilityCenter/workOrderCenter/.vue'], resolve)
    component: resolve => require(['@/views/futureService/workOrderManagement/transferRecord.vue'], resolve)
  },
  // 消息中心 - 列表
  {
    path: 'pubCapCen/msgCent/messageList',
    name: 'pubCapMsgCentMessageList',
    meta: {
      title: '消息中心'
    },
    component: resolve => require(['@/views/publicCapabilityCenter/messageCenter/messageList.vue'], resolve)
  }
];

export default routes;
