<template>
  <div class="v-dialog">
    <el-dialog
      :title="title"
      :visible.sync="model"
      :width="width"
      :close-on-click-modal="closeOnClickModal"
      @close="close"
      :append-to-body="appendToBody"
      :custom-class="customClass"
      :top="top"
    >
      <slot></slot>
      <slot name="footer">
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancel" v-if="isShowcancelBtn">{{
            cancelTxt
          }}</el-button>
          <el-button type="primary" @click="confirm">{{ sureTxt }}</el-button>
        </span>
      </slot>
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { Dialog } from "element-ui";
Vue.use(Dialog);
export default {
  watch: {
    value(newValue) {
      this.model = newValue;
    },
    model(newValue) {
      this.$emit("input", newValue);
    },
  },
  components: {},
  props: {
    title: {
      type: String,
      default: "",
    },
    sureTxt: {
      type: String,
      default: "确 定",
    },
    cancelTxt: {
      type: String,
      default: "取 消",
    },
    confirmButtonColor: {
      type: String,
      default: "",
    },
    showCancelButton: {
      type: Boolean,
      default: true,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "30%",
    },
    columns: {
      type: Array,
      default: function () {
        return [];
      },
    },
    //是否显示取消按钮
    isShowcancelBtn: {
      type: Boolean,
      default: true,
    },
    // 点击遮罩层关闭弹窗
    closeOnClickModal: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: '',
    },
    top: {
      type: String,
      default: '15vh',
    },
  },
  data() {
    return {
      model: this.value,
    };
  },
  methods: {
    // beforeClose(action, done) {
    //   if (action === "confirm") {
    //     return done();
    //   } else {
    //     return done();
    //   }
    // },
    confirm() {
      this.$emit("confirm");
    },
    cancel() {
      this.model = false;
      this.$emit("cancel");
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="less" scope>
.dialog-footer {
  display: flex;
  justify-content: space-between;
}
</style>
