const routes = [
  // 转派记录
  {
    path: "futureService/transferRecord",
    name: "transferRecord",
    meta: {
      title: "转派记录",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/workOrderManagement/transferRecord",
      ], resolve),
  },
  // 工单订单
  {
    path: "futureService/workOrder",
    name: "workOrder",
    meta: {
      title: "工单订单",
    },
    component: (resolve) =>
      require(["@/views/futureService/workOrderManagement/workOrder"], resolve),
  },
  // 工单类型
  {
    path: "futureService/workOrderType",
    name: "workOrderType",
    meta: {
      title: "工单类型",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/workOrderManagement/workOrderType",
      ], resolve),
  },
  // 人员配置
  {
    path: "futureService/staffing",
    name: "staffing",
    meta: {
      title: "人员配置",
    },
    component: (resolve) =>
      require(["@/views/futureService/workOrderManagement/staffing"], resolve),
  },
  //积分配置
  {
    path: "futureService/bonusPointSettingList",
    name: "bonusPointSettingList",
    meta: {
      title: "积分配置",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/bonusPointSetting/bonusPointSettingList",
      ], resolve),
  },
  // 商户管理
  {
    path: "futureService/merchantList",
    name: "merchantList",
    meta: {
      title: "商户管理",
    },
    component: (resolve) =>
      require(["@/views/futureService/pointsMall/merchantList"], resolve),
  },
  {
    path: "futureService/goodsList",
    name: "goodsList",
    meta: {
      title: "商户列表",
    },
    component: (resolve) =>
      require(["@/views/futureService/pointsMall/goodsList"], resolve),
  },
  {
    path: "futureService/merchantForm",
    name: "merchantForm",
    meta: {
      echo: "futureService/merchantList",
      title: "商户详情",
    },
    component: (resolve) =>
      require(["@/views/futureService/pointsMall/merchantForm"], resolve),
  },
  // 商品
  // {
  //   path: "futureService/goodsList",
  //   name: "goodsList",
  //   meta: {
  //     title: "商品列表",
  //   },
  //   component: (resolve) =>
  //     require(["@/views/futureService/pointsMall/goodsList"], resolve),
  // },
  {
    path: "futureService/goodsEdit",
    name: "goodsEdit",
    meta: {
      echo: "futureService/goodsList",
      title: "商品编辑",
    },
    component: (resolve) =>
      require(["@/views/futureService/pointsMall/goodsEdit"], resolve),
  },
  {
    path: "futureService/pointsMallOrderList",
    name: "pointsMallOrderList",
    meta: {
      title: "兑换订单列表",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/pointsMall/pointsMallOrderList",
      ], resolve),
  },
  //场地
  {
    path: "futureService/placeTypeList",
    name: "placeTypeList",
    meta: {
      title: "场地类型列表",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/reservationPlace/placeTypeList",
      ], resolve),
  },
  {
    path: "futureService/placeList",
    name: "placeList",
    meta: {
      title: "场地管理列表",
    },
    component: (resolve) =>
      require(["@/views/futureService/reservationPlace/placeList"], resolve),
  },
  {
    path: "futureService/placeEdit",
    name: "placeEdit",
    meta: {
      echo: "futureService/placeList",
      title: "场地编辑",
    },
    component: (resolve) =>
      require(["@/views/futureService/reservationPlace/placeEdit"], resolve),
  },
  {
    path: "futureService/placeOrderList",
    name: "placeOrderList",
    meta: {
      title: "场地订单列表",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/reservationPlace/placeOrderList",
      ], resolve),
  },
  // 广播站
  {
    path: "futureService/broadcastList",
    name: "broadcastList",
    meta: {
      title: "广播站列表",
    },
    component: (resolve) =>
      require(["@/views/futureService/heBroadcast/broadcastList.vue"], resolve),
  },
  {
    path: "futureService/signList",
    name: "signList",
    meta: {
      echo: "futureService/broadcastList",
      title: "广播签收列表",
    },
    component: (resolve) =>
      require(["@/views/futureService/heBroadcast/signList.vue"], resolve),
  },
  {
    path: "futureService/broadcastForm",
    name: "broadcastForm",
    meta: {
      echo: "futureService/broadcastList",
      title: "广播站表单",
    },
    component: (resolve) =>
      require(["@/views/futureService/heBroadcast/broadcastForm.vue"], resolve),
  },
  // 问卷调查
  {
    path: "futureService/questionnaireList",
    name: "questionnaireList",
    meta: {
      title: "问卷调查列表",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/questionnaire/questionnaireList.vue",
      ], resolve),
  },
  {
    path: "futureService/questionnaireEdit",
    name: "questionnaireEdit",
    meta: {
      echo: "futureService/questionnaireList",
      title: "问卷调查表单",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/questionnaire/questionnaireEdit.vue",
      ], resolve),
  },
  {
    path: "futureService/questionAnswerList",
    name: "questionAnswerList",
    meta: {
      echo: "futureService/questionnaireList",
      title: "参与人列表",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/questionnaire/questionAnswerList.vue",
      ], resolve),
  },
  // 投票
  {
    path: "futureService/vote",
    name: "vote",
    meta: {
      title: "投票",
    },
    component: (resolve) =>
      require(["@/views/futureService/vote/index.vue"], resolve),
  },
  // 房屋产权人审核
  {
    path: "futureService/housePropertyOwnerExamine",
    name: "housePropertyOwnerExamine",
    meta: {
      title: "房屋产权人审核",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/vote/housePropertyOwnerExamine.vue",
      ], resolve),
  },
  // 生活通管理
  {
    path: "futureService/lifeExpert",
    name: "lifeExpert",
    meta: {
      title: "生活通管理",
    },
    component: (resolve) =>
      require(["@/views/futureService/lifeExpert/index.vue"], resolve),
  },
  {
    path: "futureService/votePeopleList",
    name: "votePeopleList",
    meta: {
      title: "投票参与人",
      echo: "futureService/vote",
    },
    component: (resolve) =>
      require(["@/views/futureService/vote/votePeopleList.vue"], resolve),
  },
  {
    path: "futureService/voteForm",
    name: "voteForm",
    meta: {
      title: "投票",
      echo: "futureService/vote",
    },
    component: (resolve) =>
      require(["@/views/futureService/vote/voteForm.vue"], resolve),
  },
  //党建
  {
    path: "futureService/onLineStudy",
    name: "onLineStudy",
    meta: {
      title: "在线学习管理",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/onLineStudy/index.vue",
      ], resolve),
  },
  {
    path: "futureService/onLineStudyForm",
    name: "onLineStudyForm",
    meta: {
      title: "在线学习管理",
      echo: "futureService/onLineStudy",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/onLineStudy/onLineStudyForm.vue",
      ], resolve),
  },
  {
    path: "futureService/classScheduleCard",
    name: "classScheduleCard",
    meta: {
      title: "课程表管理",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/classScheduleCard/index.vue",
      ], resolve),
  },
  {
    path: "futureService/teacherLibrary",
    name: "teacherLibrary",
    meta: {
      title: "师资库管理",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/teacherLibrary/index.vue",
      ], resolve),
  },
  {
    path: "futureService/partyMember",
    name: "partyMember",
    meta: {
      title: "党员管理",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/partyMember/index.vue",
      ], resolve),
  },
  {
    path: "futureService/partyMemberForm",
    name: "partyMemberForm",
    meta: {
      title: "党员管理",
      echo: "futureService/partyMember",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/partyMember/partyMemberForm.vue",
      ], resolve),
  },
  {
    path: "futureService/partyInfo",
    name: "partyInfo",
    meta: {
      title: "党建资讯",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/partyInfo/index.vue",
      ], resolve),
  },
  {
    path: "futureService/partyInfoForm",
    name: "partyInfoForm",
    meta: {
      title: "党建资讯",
      echo: "futureService/partyInfo",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/partyInfo/partyInfoForm.vue",
      ], resolve),
  },
  {
    path: "futureService/rightsComment",
    name: "rightsComment",
    meta: {
      title: "民主评议",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/rightsComment/index.vue",
      ], resolve),
  },
  {
    path: "futureService/rightsCommentForm",
    name: "rightsCommentForm",
    meta: {
      title: "民主评议",
      echo: "futureService/rightsComment",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/rightsComment/rightsCommentForm.vue",
      ], resolve),
  },
  {
    path: "futureService/canteenManage",
    name: "canteenManage",
    meta: {
      title: "食堂管理",
      echo: "futureService/canteenManage",
    },
    component: (resolve) =>
      require(["@/views/futureService/canteenManage/index.vue"], resolve),
  },
  {
    path: "futureService/canteenOrder",
    name: "canteenOrder",
    meta: {
      title: "食堂订单",
      echo: "futureService/canteenOrder",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/canteenManage/canteenOrder.vue",
      ], resolve),
  },
  {
    path: "futureService/setMenu",
    name: "setMenu",
    meta: {
      title: "设置菜品",
      echo: "futureService/setMenu",
    },
    component: (resolve) =>
      require(["@/views/futureService/canteenManage/setMenu.vue"], resolve),
  },
  {
    path: "futureService/canteenImport",
    name: "canteenImport",
    meta: {
      title: "导入套餐",
      echo: "futureService/canteenImport",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/canteenManage/canteenImport.vue",
      ], resolve),
  },
  {
    path: "futureService/propertyCompanyManagement",
    name: "propertyCompanyManagement",
    meta: {
      title: "物业公司管理",
      echo: "futureService/propertyCompanyManagement",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/propertyCompanyManagement/index.vue",
      ], resolve),
  },
  {
    path: "futureService/propertyTopic",
    name: "propertyTopic",
    meta: {
      title: "物业公告管理",
      echo: "futureService/propertyTopic",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/propertyCompanyManagement/propertyTopic.vue",
      ], resolve),
  },
  {
    path: "futureService/propertyTopicForm",
    name: "propertyTopicForm",
    meta: {
      echo: "futureService/propertyTopic",
      title: "超话区表单",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/propertyCompanyManagement/propertyTopicForm.vue",
      ], resolve),
  },
];

export default routes;
