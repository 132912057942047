<template>
  <div>
    <div
      v-if="previewMode == 0"
      class="my-gallery"
      itemscope
      itemtype="http://schema.org/ImageGallery"
    >
      <a
        class="figure-wrapper"
        v-for="(item, index) in slides"
        :key="index"
        :href="item.src"
        itemprop="contentUrl"
      >
        <img
          @click="actionPreview(index, $event)"
          :style="imgStyle"
          class="preview-image"
          :src="item.msrc"
          :alt="item.alt"
        />
      </a>
    </div>
    <preview-template v-if="needAddTemplate"></preview-template>
  </div>
</template>

<script>
import PhotoSwipe from "photoswipe/dist/photoswipe";
import PhotoSwipeUIDefault from "photoswipe/dist/photoswipe-ui-default";
import PreviewTemplate from "./preview-template";
import _ from "lodash";

// 当前存在的图片库引用计数
var galleryCount = 0;

// @group 基础组件
// @title Preview 图片预览框
export default {
  name: "v-preview",
  components: {
    PreviewTemplate,
  },
  props: {
    /* 图片数组，可以是对象数组，也可以是字符串数组
    `[{
      src: '大图地址',
      msrc: '小图地址',
      alt: '图片信息',
    }]`
    */
    imgs: {
      type: Array,
      required: true,
    },
    // 预览组件的模式
    // `（默认0是内置缩略图模式；1自定义模式（外部方法调用））`
    previewMode: {
      type: Number,
      // `0`
      default: 0,
    },
    // 预览页面配置`（具体可查photoSwipe配置项)`
    options: {
      type: Object,
      /**
      `{
          barsSize: { top: 0, bottom: 0 },
          fullscreenEl: true,
          shareEl: false,
          showHideOpacity: true,
          zoomEl: true,
          captionEl: false,
          history: false
        }`
      */
      default: function () {
        return {
          barsSize: { top: 0, bottom: 0 },
          fullscreenEl: true,
          shareEl: false,
          showHideOpacity: true,
          zoomEl: true,
          captionEl: false,
          history: false,
          clickToCloseNonZoomable: false,
        };
      },
    },
    // 内置缩略图样式
    imgStyle: {
      type: Object,
      /**
      `{
        width: '132px',
        height: '132px',
        padding: '0',
        margin: '0 18px 10px 0'
      }`
      */
      default: function () {
        return {
          width: "132px",
          height: "132px",
          padding: "0",
          margin: "0 18px 10px 0",
        };
      },
    },
  },
  created() {
    galleryCount += 1;
  },
  mounted() {},
  computed: {
    slides() {
      // 处理数组
      let slides = [];
      this.imgs.forEach(function (item, index, ary) {
        let slide = {};
        if (_.isString(item)) {
          slide.src = item;
          slide.msrc = item;
        } else {
          slide = item;
          slide.msrc = item.msrc || item.src;
        }
        slides.push(slide);
      });
      return slides;
    },
    needAddTemplate() {
      // 是否需要加载公共dom
      return galleryCount <= 1;
    },
  },
  destroyed() {
    // 引用计数减一
    galleryCount -= 1;
  },
  methods: {
    actionPreview(index, e, disableAnimation) {
      // 自定义触发预览事件
      e = e || window.event;
      e.preventDefault ? e.preventDefault() : (e.returnValue = false);
      let actionDom = e.target || e.srcElement;
      let items = this.slides.map(function (v) {
        return {
          w: 0,
          h: 0,
          el: actionDom,
          ...v,
        };
      });
      // 直接获取传入的dom作为缩略图的dom
      let getThumbBoundsFn = function (index) {
        // See Options -> getThumbBoundsFn section of documentation for more info
        var thumbnail = actionDom;
        let pageYScroll =
          window.pageYOffset || document.documentElement.scrollTop;
        let rect = thumbnail.getBoundingClientRect();
        return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
      };
      let photoSwipeOptions = this.configureSwipeOpts(
        index,
        getThumbBoundsFn,
        disableAnimation
      );
      // exit if index not found
      if (isNaN(photoSwipeOptions.index)) {
        return;
      }
      this.initPhotoSwipe(items, photoSwipeOptions);
    },
    configureSwipeOpts(index, getThumbBoundsFn, disableAnimation) {
      const self = this;
      // 配置弹出框选项
      let photoSwipeOptions = {
        getThumbBoundsFn: getThumbBoundsFn,
        ...self.options,
      };
      photoSwipeOptions.index = parseInt(index, 10);
      if (disableAnimation) {
        photoSwipeOptions.showAnimationDuration = 0;
      }
      return photoSwipeOptions;
    },
    initPhotoSwipe(items, photoSwipeOptions) {
      const self = this;
      // 初始化图片预览框
      let pswpElement = document.querySelectorAll(".pswp")[0];
      // Pass data to PhotoSwipe and initialize it
      let gallery = new PhotoSwipe(
        pswpElement,
        PhotoSwipeUIDefault,
        items,
        photoSwipeOptions
      );
      gallery.listen("gettingData", function (index, item) {
        if (item.w < 1 || item.h < 1) {
          // unknown size
          var img = new Image();
          img.onload = function () {
            // will get size after load
            item.w = this.width; // set image width
            item.h = this.height; // set image height
            // gallery.invalidateCurrItems() // reinit Items
            gallery.updateSize(true); // reinit Items
          };
          img.src = item.src; // let's download image
        }
      });
      gallery.init();
      // Gallery starts closing
      gallery.listen("close", function () {
        // 关闭预览图
        self.$emit("close");
      });
    },
  },
};
</script>

<style scoped lang="less">
.my-gallery {
  .figure-wrapper {
    display: inline-block;
  }
  .preview-image {
    width: 100%;
    height: 100%;
  }
}
</style>
