const routes = [
  {
    path: "developer/dataTable",
    name: "dataTable",
    meta: {
      title: "数据报表",
    },
    component: (resolve) =>
      require(["@/views/developer/application/dataTable.vue"], resolve),
  },
  {
    path: "developer/myApplication",
    name: "myApplication",
    meta: {
      title: "我的应用",
    },
    component: (resolve) =>
      require(["@/views/developer/application/myApplication.vue"], resolve),
  },
  {
    path: "developer/myService",
    name: "myService",
    meta: {
      title: "我的订阅",
    },
    component: (resolve) =>
      require(["@/views/developer/service/myService.vue"], resolve),
  },
  {
    path: "developer/interfaceWord",
    name: "interfaceWord",
    meta: {
      title: "接口文档",
    },
    component: (resolve) =>
      require(["@/views/developer/service/interfaceWord.vue"], resolve),
  },
  {
    path: "help/helpWord",
    name: "helpWord",
    meta: {
      title: "帮助中心",
    },
    component: (resolve) =>
      require(["@/views/developer/help/helpWord.vue"], resolve),
  },
];

export default routes;
