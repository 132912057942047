<template>
  <div class="v-control v-input">
    <label v-if="label" v-text="label"></label>
    <el-input
      ref="input"
      v-model="model"
      :placeholder="placeholder"
      :size="size"
      :name="name"
      :type="type"
      :clearable="clearable"
      :disabled="disabled"
      :autocomplete="autocomplete"
      :min="min"
      :max="max"
      :prefix-icon="prefixIcon"
      :suffix-icon="suffixIcon"
      v-bind="{ ...bind, ...nativeProps }"
      :maxlength="maxlength"
      :tabindex="tabindex"
      :autosize="autosize"
      @keyup="keyupEvent"
      @focus="focusEvent"
      @clear="clearEvent"
      @blur="blurEvent"
    >
      <template #suffix>
        <slot name="suffix"></slot>
      </template>
    </el-input>
  </div>
</template>

<script>
import Vue from "vue";
import { Input } from "element-ui";
Vue.use(Input);
export default {
  props: {
    // 自适应内容高度，只对 type="textarea" 有效，可传入对象，如，{ minRows: 2, maxRows: 6 }
    autosize: [Boolean, Object],
    tabindex: String,
    // input 原生属性
    min: String,
    // input 原生属性
    max: {
      type: [String, Number],
      default: "",
    },
    prefixIcon: String,
    suffixIcon: String,
    maxlength: {
      type: Number,
      default: 1000,
    },
    // 输入框宽度
    width: {
      type: [Number, String],
      // `144`
      default: 188,
    },
    nativeProps: {},
    size: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    autocomplete: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      default: function () {
        return [];
      },
    },
    modeFlag: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      inputWidth: this.width,
      model: this.value,
    };
  },
  watch: {
    value(newValue, oldValue) {
      this.changeModel(newValue);
    },
    model(newValue) {
      this.$emit("input", newValue, this);
    },
  },
  created() {
    this.bind = {
      style: {
        width: String(this.inputWidth).includes("%")
          ? this.inputWidth
          : this.inputWidth + "px",
      },
    };
  },
  methods: {
    focus() {
      // 获取焦点
      this.$refs.input.focus();
    },
    blur() {
      // 失去焦点
      this.$refs.input.blur();
    },
    clearEvent() {
      this.$emit("clear");
    },
    focusEvent() {
      this.$emit("focus");
    },
    blurEvent(ev) {
      this.filterForDOMXSS(this.model, this.modeFlag);
      this.$emit("blur", ev);
    },
    keyupEvent() {
      this.$emit("keyup");
    },
    changeModel(newValue) {
      this.model = newValue;
      // 输入框的值改变时触发
      this.$emit("change", newValue);
    },
    change(value) {
      this.$emit("change", value);
    },

    /** 前端拦截 xss 攻击  */
    filterForDOMXSS(value, modeFlag) {
      //value是要传入的文本，而modeFlag是使用的过滤强度
      var searchStatus = false;
      var crucialWord = [
        // "javascript",
        // "eval",
        // "alert",
        // "onclick",
        // "ondblclick",
        // "onmousedown",
        // "onmouseup",
        // "onmouseover",
        // "onmouseout",
        // "onmousemove",
        // "onkeydown",
        // "onkeyup",
        // "onkeypress",
        "<script+.*>",
        "<p+.*>",
        "<a+.*>",
        "<img+.*>",
        "<h+.*>",
        "<textarea+.*>",
        "<span+.*>",
        "<div+.*>",
        "<td+.*>",
        "<tr+.*>",
        "<th+.*>",
        "<li+.*>",
        "<ul+.*>",
        "<option+.*>",
        "<select+.*>",
        "<del+.*>",
        "<ol+.*>",
        "<label+.*>",
        "<button+.*>",
        "<style+.*>",
        "<em+.*>",
        "<strong+.*>",
        "<strike+.*>",
        "<b+.*>",
        "<s+.*>",
        "<u+.*>",
        "<sub+.*>",
        "<sup+.*>",
        "<small+.*>",
        "<dl+.*>",
        "<dt+.*>",
        "<i+.*>",
        "<pre+.*>",
        "<q+.*>",
        "<blockquote+.*>",
        "<br+.*>",
        "<code+.*>",
        "<cite+.*>",
        "\\$\\(+.*\\)",
      ]; //过滤关键词，可以后续添加
      for (var i = 0; i < crucialWord.length; i++) {
        var check = new RegExp(crucialWord[i], "gi");
        if (check.exec(value)) {
          searchStatus = true;
          break;
        }
      }
      if (searchStatus) {
        // value = value
        //   .replace(/javascript/gi, "")
        //   .replace(/eval/gi, "")
        //   .replace(/\$\(+.*\)/g, "")
        //   .replace(/alert/gi, "")
        //   .replace(/onclick/gi, "")
        //   .replace(/ondblclick/gi, "")
        //   .replace(/onmousedown/gi, "")
        //   .replace(/onmouseup/gi, "")
        //   .replace(/onmouseover/gi, "")
        //   .replace(/onmouseout/gi, "")
        //   .replace(/onmousemove/gi, "")
        //   .replace(/onkeydown/gi, "")
        //   .replace(/onkeyup/gi, "")
        //   .replace(/onkeypress/gi, "");

        if (modeFlag == 0) {
          //仅过滤标签模式
          value = value
            .replace(/<script+.*>/gi, "")
            .replace(/<p+.*>/gi, "")
            .replace(/<a+.*>/gi, "")
            .replace(/<img+.*>/gi, "")
            .replace(/<h+.*>/gi, "")
            .replace(/<textarea+.*>/gi, "")
            .replace(/<span+.*>/gi, "")
            .replace(/<div+.*>/gi, "")
            .replace(/<td+.*>/gi, "")
            .replace(/<tr+.*>/gi, "")
            .replace(/<th+.*>/gi, "")
            .replace(/<li+.*>/gi, "")
            .replace(/<ul+.*>/gi, "")
            .replace(/<option+.*>/gi, "")
            .replace(/<select+.*>/gi, "")
            .replace(/<del+.*>/gi, "")
            .replace(/<ol+.*>/gi, "")
            .replace(/<label+.*>/gi, "")
            .replace(/<button+.*>/gi, "")
            .replace(/<style+.*>/gi, "")
            .replace(/<em+.*>/gi, "")
            .replace(/<strong+.*>/gi, "")
            .replace(/<strike+.*>/gi, "")
            .replace(/<b+.*>/gi, "")
            .replace(/<s+.*>/gi, "")
            .replace(/<u+.*>/gi, "")
            .replace(/<sub+.*>/gi, "")
            .replace(/<sup+.*>/gi, "")
            .replace(/<small+.*>/gi, "")
            .replace(/<dl+.*>/gi, "")
            .replace(/<dt+.*>/gi, "")
            .replace(/<i+.*>/gi, "")
            .replace(/<pre+.*>/gi, "")
            .replace(/<q+.*>/gi, "")
            .replace(/<blockquote+.*>/gi, "")
            .replace(/<br+.*>/gi, "")
            .replace(/<code+.*>/gi, "")
            .replace(/<cite+.*>/gi, "");
        } else if (modeFlag == 1) {
          //过滤符号模式
          value = value
            .replace(/</g, "")
            .replace(/>/g, "")
            .replace(/&lt;/gi, "")
            .replace(/gt&;/gi, "")
            .replace(/\$\(\)/g, "");
        } else if (modeFlag == 2) {
          //全过滤模式（不推荐）
          value = value.replace(/<\w+.*>/g, "");
        }
        // return {
        //   value: value,
        //   status: searchStatus,
        //   warningMessage: "系统检测到您疑似正在输入恶意代码，请注意您的行为！",
        // };
        this.$message.warning(
          // "系统检测到您疑似正在输入恶意代码，已将恶意代码清空！"
          "您输入的内容中包含非法字符串，已为您自动过滤"
        );
      }
      // console.log(value, "val---");
      this.model = value;

      // else {
      //   return { value: value, status: searchStatus, warningMessage: "" };
      // }
    },
  },
};
</script>

<style lang="less">
.v-input {
  label {
    font-size: 14px;
    columns: #999;
    margin-right: 10px;
  }
}
</style>
