<template>
  <el-tabs
    :type="type"
    :value="value"
    @input="onInput"
    @tab-click="handleClick"
  >
    <el-tab-pane
      v-for="(tab, index) in tabs"
      :key="index"
      :disabled="tab.disabled || false"
      :closable="tab.closable || false"
      :lazy="tab.lazy || false"
      :name="tab.name"
      :label="tab.label || ''"
    >
    </el-tab-pane>
  </el-tabs>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: "card",
    },
    /** - tabs # 格式
     *    text # 选项卡值 必填
     *    disabled # 是否禁用 非必填
     *    name # 类似于唯一key 必填
     *    closable # 标签是否可关闭 非必填
     *    lazy # 标签是否延迟渲染 非必填
     *    label # 选项卡标题 非必填
     */
    tabs: {
      type: Array,
      required: true,
    },
    // 当前选中项
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      // activeName: "second",
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    onInput(val) {
      this.$emit("input", val);
    },
  },
};
</script>

<style lang="less" scoped>
.el-tabs {
  ::v-deep .el-tabs__header {
    margin: 0;
  }
}
</style>
