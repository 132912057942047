const routes = [
  {
    path: "futureTreat/communityTreat",
    name: "communityTreat",
    meta: {
      title: "社区治理通",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/communityTreat/index.vue"], resolve),
  },
  {
    path: "futureTreat/epidemicPrevent",
    name: "epidemicPrevent",
    meta: {
      title: "防疫登记",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/epidemicPrevent/index.vue"], resolve),
  },
  {
    path: "futureTreat/epidemicPreventForm",
    name: "epidemicPreventForm",
    meta: {
      title: "防疫登记表单",
      echo: "futureTreat/epidemicPrevent",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/epidemicPrevent/epidemicPreventForm.vue",
      ], resolve),
  },
  {
    path: "futureTreat/epidemicPostList",
    name: "epidemicPostList",
    meta: {
      title: "防疫登记提交记录",
      echo: "futureTreat/epidemicPrevent",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/epidemicPrevent/epidemicPostList.vue",
      ], resolve),
  },
  {
    path: "futureTreat/epidemicPostDetail",
    name: "epidemicPostDetail",
    meta: {
      title: "防疫登记提交记录详情",
      echo: "futureTreat/epidemicPrevent",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/epidemicPrevent/epidemicPostDetail.vue",
      ], resolve),
  },
  {
    path: "futureTreat/epidemicInfo",
    name: "epidemicInfo",
    meta: {
      title: "防疫资讯",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/epidemicPrevent/epidemicInfo.vue",
      ], resolve),
  },
  {
    path: "futureTreat/epidemicInfoForm",
    name: "epidemicInfoForm",
    meta: {
      title: "防疫资讯",
      echo: "futureTreat/epidemicInfo",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/epidemicPrevent/epidemicInfoForm.vue",
      ], resolve),
  },
  // 社区一张图
  {
    path: "futureTreat/communityAMap",
    name: "CommunityAMap",
    meta: {
      title: "社区一张图",
    },
    component: (resolve) => require(["@/views/futureTreat/communityAMap/index.vue"], resolve),
  },
  {
    path: "futureTreat/communityAMap/add",
    name: "CommunityAMapAdd",
    meta: {
      title: "社区一张图",
      echo: "futureTreat/communityAMap",
    },
    component: (resolve) => require(["@/views/futureTreat/communityAMap/add.vue"], resolve),
  },
  {
    path: "futureTreat/communityAMap/edit",
    name: "CommunityAMapEdit",
    meta: {
      title: "社区一张图",
      echo: "futureTreat/communityAMap",
    },
    component: (resolve) => require(["@/views/futureTreat/communityAMap/edit.vue"], resolve),
  },
];

export default routes;
