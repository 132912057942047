var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"v-upload"},[_vm._l((_vm.videoUrls ? _vm.videoUrls.split(',') : []),function(pic,index){return _c('div',{key:`${index}_${pic}`,staticClass:"pic-list"},[_c('video',{staticClass:"upload-list__item-thumbnail",style:({
          'object-fit': _vm.fit,
        }),attrs:{"src":pic,"controls":"controls"}}),_vm._m(0,true),_c('span',{staticClass:"upload-list__item-actions"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.disabled),expression:"!disabled"}],staticClass:"upload-list__item-delete",on:{"click":function($event){return _vm.handleRemove(pic)}}},[_c('i',{staticClass:"el-icon-delete"})])])])}),_vm._l((_vm.loadList),function(item,index){return _c('div',{key:`load_${index}`,staticClass:"pic-list",attrs:{"loading":+item.percentage === 100}},[_c('el-progress',{staticClass:"progress",attrs:{"type":"circle","percentage":item.percentage ? Number(item.percentage.toFixed(0)) : 0}})],1)}),_c('el-upload',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"pic-upload",class:{ hide: _vm.hideUpload || _vm.disabled },attrs:{"multiple":true,"action":_vm.uploadImgUrl,"list-type":"picture-card","disabled":_vm.disabled,"limit":_vm.limit,"headers":{
        ..._vm.defaultHeaders,
        ..._vm.headers,
      },"file-list":_vm.fileList,"before-upload":_vm.beforeUpload,"on-change":_vm.change,"on-error":_vm.errorUpload,"on-success":_vm.successUpload,"on-exceed":_vm.onExceed,"on-progress":_vm.onProgress,"show-file-list":false}},[_c('i',{staticClass:"el-icon-plus"})])],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"upload-list__item-status-label"},[_c('i',{staticClass:"el-icon-upload-success el-icon-check"})])
}]

export { render, staticRenderFns }