import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import { _localStorage } from "@/utils/utils";
// import getters from "./getters";
Vue.use(Vuex);
const modulesFiles = require.context("./modules", true, /\.js$/);

const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1");
  const value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});

export default new Vuex.Store({
  modules,
  state: {
    authorization: "Basic c3dvcmQ6c3dvcmRfc2VjcmV0",
    // authorization: "Basic YmlnU2NyZWVuOmJpZ1NjcmVlbl9zZWNyZXQ=",
    // "blade-auth":
    //   "bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJpc3N1c2VyIiwiYXVkIjoiYXVkaWVuY2UiLCJ0ZW5hbnRfaWQiOiIwMDAwMDAiLCJyb2xlX25hbWUiOiIiLCJjb21wYW55SWQiOiItMSIsInVzZXJfaWQiOiIxIiwicm9sZV9pZCI6IjExIiwidXNlcl9uYW1lIjoi57O757uf566h55CG5ZGYIiwiaWRlbnRpdHkiOiIwIiwicm9sZUFsaWFzIjoiYWRtaW5pc3RyYXRvciIsInRva2VuX3R5cGUiOiJhY2Nlc3NfdG9rZW4iLCJhY2NvdW50IjoiYWRtaW4iLCJvcmdJZCI6Ii0xIiwiY2xpZW50X2lkIjoic3dvcmQiLCJleHAiOjE2NDAzOTg4NzUsIm5iZiI6MTYzODc1ODYxM30.sz4nxtKSp7b7klc5KTYSPZb14i5sd8NL8vFP2lEcv_bt9epcjl8QFeZnguTQ6RspOR7KdHwwPQHGioPIt3FoPQ",
  },
  mutations: {},
  actions: {
    async refreshToken({ commit, state }, payload) {
      console.log(commit, state);
      let url = "/gateway/hc-auth/token";
      let refreshToken = JSON.parse(
        localStorage.getItem("userInfo")
      ).refreshToken;
      const formData = new FormData();
      formData.append("grantType", "refresh_token");
      formData.append("refreshToken", refreshToken);
      let res = await axios.post(`${url}`, formData, {
        headers: {
          authorization: state.authorization,
          "User-Type": "web",
        },
      });
      if (res.status == 200) {
        if (res.data.code == 200) {
          let userInfo = res.data.data;
          _localStorage.setItem("userInfo", JSON.stringify(userInfo));
          commit("app/setUserInfo", userInfo);
        }
      }
    },
  },
  // getters,
});
