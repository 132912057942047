const routes = [
  {
    path: "futureNeighbourhood/talentActivities",
    name: "talentActivities",
    meta: {
      title: "达人活动",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/applyActivity/talentActivities.vue",
      ], resolve),
  },
  {
    path: "futureNeighbourhood/talentActivitiesForm",
    name: "talentActivitiesForm",
    meta: {
      echo: "futureNeighbourhood/talentActivitiesForm",
      title: "达人活动编辑",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/applyActivity/talentActivitiesForm.vue",
      ], resolve),
  },
  // 活动报名
  {
    path: "futureNeighbourhood/applyActivity",
    name: "applyActivity",
    meta: {
      title: "活动列表",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/applyActivity/index"], resolve),
  },
  {
    path: "futureNeighbourhood/volunteerList",
    name: "volunteerList",
    meta: {
      title: "志愿者列表",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/applyActivity/volunteerList",
      ], resolve),
  },
  {
    path: "futureNeighbourhood/addActivity",
    name: "addActivity",
    meta: {
      echo: "futureNeighbourhood/applyActivity",
      title: "活动编辑",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/applyActivity/addActivity.vue",
      ], resolve),
  },
  {
    path: "futureNeighbourhood/activityApplicants",
    name: "activityApplicants",
    meta: {
      echo: "futureNeighbourhood/applyActivity",
      title: "参与人列表",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/applyActivity/activityApplicants.vue",
      ], resolve),
  },
  {
    path: "futureNeighbourhood/activityPointForm",
    name: "activityPointForm",
    meta: {
      echo: "futureNeighbourhood/applyActivity",
      title: "编辑积分",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/applyActivity/activityPointForm.vue",
      ], resolve),
  },
  {
    path: "futureNeighbourhood/activityFeedback",
    name: "activityFeedback",
    meta: {
      echo: "futureNeighbourhood/applyActivity",
      title: "活动反馈",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/applyActivity/activityFeedback.vue",
      ], resolve),
  },
  {
    path: "futureNeighbourhood/activityAuditList",
    name: "activityAuditList",
    meta: {
      title: "活动审核列表",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/applyActivity/activityAuditList.vue",
      ], resolve),
  },
  //社区共建
  {
    path: "futureNeighbourhood/communityConstructionList",
    name: "communityConstructionList",
    meta: {
      title: "共建列表",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/communityConstruction/communityConstructionList",
      ], resolve),
  },
  // 公益捐赠
  {
    path: "futureNeighbourhood/donateList",
    name: "donateList",
    meta: {
      title: "捐赠列表",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/donateManage/donateList.vue",
      ], resolve),
  },
  // 互动
  {
    path: "futureNeighbourhood/interaction",
    name: "interaction",
    meta: {
      title: "互动",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/interaction/index.vue"], resolve),
  },
  // 社区达人风采
  {
    path: "futureNeighbourhood/partnerList",
    name: "partnerList",
    meta: {
      title: "社区达人风采",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/partner/partnerList.vue"], resolve),
  },
  {
    path: "futureNeighbourhood/partnerForm",
    name: "partnerForm",
    meta: {
      echo: "futureNeighbourhood/partnerList",
      title: "社区达人风采表单",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/partner/partnerForm.vue"], resolve),
  },
  {
    path: "futureNeighbourhood/talentApplyList",
    name: "talentApplyList",
    meta: {
      title: "社区达人申请列表",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/userManage/talentApplyList.vue",
      ], resolve),
  },
  // 超话
  {
    path: "futureNeighbourhood/topicList",
    name: "topicList",
    meta: {
      title: "超话区列表",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/topic/topicList.vue"], resolve),
  },
  // 居民超话管理
  {
    path: "futureNeighbourhood/residentTopicList",
    name: "residentTopicList",
    meta: {
      // echo: "futureNeighbourhood/topicList",
      title: "居民超话管理",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/topic/residentTopicList.vue",
      ], resolve),
  },
  {
    path: "futureNeighbourhood/topicForm",
    name: "topicForm",
    meta: {
      echo: "futureNeighbourhood/topicList",
      title: "超话区表单",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/topic/topicForm.vue"], resolve),
  },
  {
    path: "futureNeighbourhood/topicSortList",
    name: "topicSortList",
    meta: {
      echo: "futureNeighbourhood/topicList",
      title: "点赞评论排行榜",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/topic/topicSortList.vue"], resolve),
  },
  // 心愿
  {
    path: "futureNeighbourhood/wishWallList",
    name: "wishWallList",
    meta: {
      title: "心愿列表",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/wishWall/wishWallList"], resolve),
  },
  // 社群申请订单
  {
    path: "futureNeighbourhood/associationApplyOrder",
    name: "associationApplyOrder",
    meta: {
      title: "社群申请订单",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/association/associationApplyOrder",
      ], resolve),
  },
  // 社群管理
  {
    path: "futureNeighbourhood/association",
    name: "association",
    meta: {
      title: "社群",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/association/index"], resolve),
  },
  // 新增/编辑 社群
  {
    path: "futureNeighbourhood/associationEdit",
    name: "associationEdit",
    meta: {
      echo: "futureNeighbourhood/association",
      title: "新增/编辑社群",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/association/associationEdit",
      ], resolve),
  },
  // 社群二级分类
  {
    path: "futureNeighbourhood/secondAssociation",
    name: "secondAssociation",
    meta: {
      echo: "futureNeighbourhood/association",
      title: "社群二级分类",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/association/secondAssociation",
      ], resolve),
  },
  //评论管理
  {
    path: "futureNeighbourhood/commentManage",
    name: "commentManage",
    meta: {
      title: "评论管理",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/commentManage/index"], resolve),
  },
  //明珠管家
  {
    path: "futureNeighbourhood/xiaoheHouseKeeper",
    name: "xiaoheHouseKeeper",
    meta: {
      title: "明珠管家",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/xiaoheHouseKeeper/index"], resolve),
  },
  //邻里公约管理
  {
    path: "futureNeighbourhood/signatureLst",
    name: "signatureLst",
    meta: {
      title: "签署列表",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/neighborhoodConventionManagement/index",
      ], resolve),
  },
  {
    path: "futureNeighbourhood/conventionEditor",
    name: "conventionEditor",
    meta: {
      title: "公约列表",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/neighborhoodConventionManagement/conventionEditor",
      ], resolve),
  },
  //明珠管家
  {
    path: "futureNeighbourhood/xiaoheHouseKeeperForm",
    name: "xiaoheHouseKeeperForm",
    meta: {
      title: "明珠管家",
      echo: "futureNeighbourhood/xiaoheHouseKeeper",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/xiaoheHouseKeeper/xiaoheHouseKeeperForm",
      ], resolve),
  },
  //装修贴士
  {
    path: "futureNeighbourhood/decorationTips",
    name: "decorationTips",
    meta: {
      title: "明珠管家",
      echo: "futureNeighbourhood/decorationTips",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/decorationTips/index"], resolve),
  },
];

export default routes;
