<template>
  <div class="communitySelect">
    <v-select
      :options="allOps"
      :disabled="disabled"
      clearable
      :filterable="filterable"
      v-model="model"
      :label="label"
    />
  </div>
</template>

<script>
import Vue from "vue";
import { getBuildListURL } from "./api.js";
// @group 业务组件
// @title 选择园区
export default {
  name: "v-cascader",
  props: {
    label: {
      type: String,
      default: "",
    },
    // 选中项的value数组，支持`.sync`
    value: {
      type: [String, Number, Array, Boolean],
      default: "",
    },
    filterable: Boolean,
    disabled: Boolean,
    specifyCollectionTypes: {
      type: [String, Number],
      default: "10",
    },
    spaceTypes: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      model: this.value,
      allOps: [],
    };
  },
  watch: {
    value(newValue, oldValue) {
      this.changeModel(newValue);
    },
    model(newValue) {
      this.$emit("input", newValue);
    },
    spaceTypes(newValue) {
      this.getBuildList();
    },
  },
  created() {
    // this.getCommunityList();
    this.getBuildList();
  },
  methods: {
    changeModel(newValue) {
      this.model = newValue;
      // 输入框的值改变时触发
      this.$emit("change", newValue, this.allOps);
    },
    change(value) {
      this.$emit("change", value);
    },
    clear() {
      this.$emit("clear");
      console.log("清除内容");
    },
    async getBuildList() {
      this.allOps = [];
      let url = "";
      if (this.spaceTypes && this.specifyCollectionTypes) {
        url = `${getBuildListURL}?specifyCollectionTypes=${this.specifyCollectionTypes}&spaceTypes=${this.spaceTypes}`;
      }
      if (!this.spaceTypes && this.specifyCollectionTypes) {
        url = `${getBuildListURL}?specifyCollectionTypes=${this.specifyCollectionTypes}`;
      }
      if (this.spaceTypes && !this.specifyCollectionTypes) {
        url = `${getBuildListURL}?spaceTypes=${this.spaceTypes}`;
      }
      let res = await this.$axios.get(url);
      if (res.code === 200) {
        res.data.forEach((item) => {
          item.label = item.name;
          item.value = item.id;
          this.allOps.push(item);
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
.communitySelect {
}
</style>
