<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState("app", ["sidebar", "menuList"]),
  },
  created() {
    // 在页面加载时读取sessionStorage里的状态信息
    // if (sessionStorage.getItem("store")) {
    //   // replaceState 替换掉store的根状态
    //   this.$store.replaceState(
    //     Object.assign(
    //       {},
    //       this.$store.state,
    //       JSON.parse(sessionStorage.getItem("store"))
    //     )
    //   );
    // } else {
    //   console.log(this.$store.state, JSON.stringify(this.$store.state));
    //   // debugger;
    //   sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    // }
    // console.log(sessionStorage.getItem("store"), "store");
    // 在页面刷新时将vuex里的信息保存到sessionStorage里
    // beforeunload事件在页面刷新时先触发
    // window.addEventListener("beforeunload", () => {
    //   sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    // });
    /** vuex app.js */
  },
  methods: {},
};
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  height: 100%;
}
@import url("./assets/less/reset.less");
@import url("./assets/less/global.less");
</style>
